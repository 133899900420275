import { useAppDispatch, useAppSelector } from '@store/hook';
import { open, close, selectProgress } from '@store/progress';

/**
 * Progress 상태 처리
 * @returns
 */
function useProgress () {
  const dispatch = useAppDispatch();
  const progress = useAppSelector(selectProgress);

  const progressOpen = (title: string, contentText: string) => {
    dispatch(
      open({
        isOpen: true,
        title,
        contentText
      })
    );
  };

  const progressClose = () => {
    dispatch(close())
  };

  return {
    progress,
    progressOpen,
    progressClose
  };
};

export default useProgress;
