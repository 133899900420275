import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import type { DialogProps } from '@mui/material';
import { DOC_TRASH_ID } from '@const';
// import { DriveDto } from '@dto/DriveDto';
import { driveApi } from '@api';
import { useAlert, useProgress } from '@hook';
import useSeletedStore from '../../store/mystore';

/**
 * onProcess => 처리 시작, 처리 중
 * onCreated => 처리 완료
 * onFailed => 처리 실패
 */
type TrashMoveConfirmProps = DialogProps & {
  onCreated: (id: string, result?: any) => void;
  onCancel: (id: string) => void;
  onFailed?: (id: string) => void;
  onProcess?: (id: string) => void;
};

export const TrashMoveConfirm: React.FC<TrashMoveConfirmProps> = (props) => {
  const { onCreated, onCancel, onProcess, ...other } = props;
  const { alertOpen } = useAlert();
  const { progressOpen, progressClose } = useProgress();
  const selected = useSeletedStore((state) => state.selected)
  const handleOk = async () => {
    try {
      progressOpen('휴지통', '선택된 항목을 휴지통으로 이동 중 입니다.');
      if (!selected || selected.length === 0) {
        throw new Error('선택된 파일 또는 디렉토리가 없습니다.');
      }

      await Promise.all(
        selected.map(async (value) =>
          await driveApi.move([`${value.path}`], value.driveType, '', 'trash',)
        )
      );

      onCreated(DOC_TRASH_ID);
    } catch (error: any) {
      alertOpen({
        title: '오류',
        content: (<Box>
          휴지통으로 이동 중 오류가 발생했습니다.<br />
          {error.message}
        </Box>),
        onOk () {
          onCancel(DOC_TRASH_ID);
        }
      });
    } finally {
      progressClose();
    }
  };

  const handleCancel = () => {
    onCancel(DOC_TRASH_ID);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        {...other}>
        <DialogTitle>휴지통</DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택하신 항목을 휴지통에 담겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={handleCancel}
          >
            취소
          </Button>
          <Button
            color="secondary"
            onClick={handleOk}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
};
