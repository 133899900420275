import React from 'react';
import { Alert, Box, Button, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';
import { AuthWrapper } from './AuthWrapper';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface LoginFormProps {
  loading: boolean;
  onLogin: (id: string, password: string) => void;
  onFindPassword: () => void;
  error?: string;
};

/**
 * 로그인
 * @param props
 * @returns
 */
export const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
  const {
    loading,
    onLogin,
    onFindPassword,
    error
  } = props;

  const scheme = yup.object().shape({
    email: yup
      .string()
      .required('아이디(이메일)을 입력해주세요.'),
    password: yup
      .string()
      .required('비밀번호를 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(scheme)
  })


  const onSubmit = async (data: any) => {
    onLogin(data.email, data.password);
  };

  return (
    <AuthWrapper
      pageName="로그인">
        {error && <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>}
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          >
          <Grid container spacing={2}>
            <Grid xs={12}>
              <TextField
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                placeholder="아이디"
                hiddenLabel
                disabled={loading}
                {...register('email')}
                error={errors.email ? true : false}
                helperText={errors.email?.message}
                />
            </Grid>
            <Grid xs={12}>
              <TextField
                type="password"
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                placeholder="비밀번호"
                hiddenLabel
                disabled={loading}
                {...register('password')}
                error={errors.password ? true : false}
                helperText={errors.password?.message}
                />
            </Grid>
            <Grid xsOffset={6} xs={6} sx={{ textAlign: 'right' }}>
              <Button
                color="inherit"
                onClick={onFindPassword}
                >
                  비밀번호 찾기
              </Button>
            </Grid>
            <Grid xs={12}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                size="medium"
                loadingPosition={'start'}
                loading={loading}
                disableElevation
                >
                  로그인
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </AuthWrapper>
  )
};
