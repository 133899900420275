import instance from './instance';
import ResultDto from '@dto/ResultDto';
import ListResultDto from '@dto/ListResultDto';
import { LiveUpdateDto } from '@dto/LiveUpdateDto';
import DetailResultDto from '@dto/DetailResultDto';

/**
 * 프린트 앱 목록
 * @param page
 * @returns
 */
export const find = async (page: number): Promise<ResultDto<ListResultDto<LiveUpdateDto>>> =>
  await instance.get('/printer/app', {
    params: {
      page
    }
  });

export const findByOne =
  async ({ version, ips }: { version: string, ips: string }): Promise<ResultDto<DetailResultDto<LiveUpdateDto>>> =>
  await instance.get(`/printer/app/${version}/${ips}`);

/**
 * 수정
 * @param params
 * @returns
 */
export const edit = async (params: LiveUpdateDto) => {
  const { version, ips, ...other } = params;
  return await instance.patch(`/printer/app/${version}/${ips}`, other);
}
