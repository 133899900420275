import React from 'react';
import { TextField as MuiTextField  } from '@mui/material';
import type { TextFieldProps as MuiTextFieldProps  } from '@mui/material';
import type { UcodField } from '@type';
import type { UseFormRegisterReturn } from 'react-hook-form';

type TextFieldProps = MuiTextFieldProps & {
  field: UcodField,
  register?: UseFormRegisterReturn<any>
};

export const TextField: React.FC<TextFieldProps> = (props) => {
  const { field: f, register, ...other } = props;
  
  return (
    <MuiTextField
      variant="outlined"
      color="secondary"
      size="small"
      fullWidth
      multiline={f.type === 'textarea'}
      rows={f.type === 'textarea' ? 4 : 1}
      placeholder={`${f.label ? f.label : f.id.toUpperCase()}을(를) 입력해주세요.`}
      {...other}
      {...register}
      />
  )
};