import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Toolbar, Typography, colors } from '@mui/material'
import theme from '@style/theme';
import { Add as AddIcon } from '@mui/icons-material';

export const BreadCrumb = (): JSX.Element => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const label = useMemo(
    () => {
      if (pathname.startsWith('/cert')) {
        return '문서 이용 인증 관리';
      } else if (pathname.startsWith('/annot')) {
        return '문서 주석 관리';
      } else if (pathname.startsWith('/org')) {
        return '조직 관리';
      } else if (pathname.startsWith('/log/system')) {
        return '시스템 로그 관리';
      } else if (pathname.startsWith('/log') || pathname.startsWith('/actionLog')) {
        return '로그 관리';
      } else if (pathname.startsWith('/liveUpdate')) {
        return '프린터 앱 관리';
      } else if (pathname.startsWith('/printerInfo')) {
        return '사용 불가 프린트 관리';
      } else if (pathname.startsWith('/admin')) {
        return '관리자 관리';
      }

      return '';
    },
    [pathname]);

  /**
   * 버튼 생성
   */
  const createButton = useMemo(() => {
    if (pathname === '/org') {
      return (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          startIcon={<AddIcon fontSize="small" />}
          onClick={() => navigate('/org/create')}
          >
            조직 생성
        </Button>
      )
    } else if (pathname === '/liveUpdate') {
      return (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          startIcon={<AddIcon fontSize="small" />}
          onClick={() => navigate('/liveUpdate/create')}
          >
            버전 생성
        </Button>
      )
    } else if (pathname === '/printerInfo') {
      return (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          startIcon={<AddIcon fontSize="small" />}
          onClick={() => navigate('/printerInfo/create')}
          >
            프린터 추가
        </Button>
      )
    } else if (pathname === '/admin') {
      return (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          startIcon={<AddIcon fontSize="small" />}
          onClick={() => navigate('/admin/create')}
          >
            관리자 추가
        </Button>
      )
    }

    return null;
  },
  // eslint-disable-next-line
  [pathname]
);

  return (
    <Toolbar
      variant="dense"
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        zIndex: theme.zIndex.drawer,
        backgroundColor: colors.common.white,
        justifyContent: 'space-between',
      }}
      >
      <Typography>{label}</Typography>
      {createButton}
    </Toolbar>
  )
};
