import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { flatten, unflatten } from 'flat';
import { Box, Button, Container, FormControl } from '@mui/material';
import {
  List as ListIcon,
  Delete as TrashIcon,
  Slideshow as ShowIcon,
  Add as AddIcon
} from '@mui/icons-material';

import api from '@api';
import Type, { UcodField } from '@type';
import { FormLabel } from '@components/Common/Form/FormLabel';
import { Select } from '@components/Common/Form/Select';
import { TextField } from '@components/Common/Form/TextField';
import { BreadCrumb } from '@components/Common/Breadcrumb';
import { useLoading, useToast } from '@hook';

export const CommonEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [fields, setFields] = useState<UcodField[]>([]);
  const { loadingOpen, loadingClose } = useLoading();
  const { toastOpen } = useToast();
  const apiKey = `${params.id}Api`;

  let scheme: { [key: string]: any } = {};

  if (params.id) {
    const { fields, edit } = Type[params.id];
    let fs: UcodField[] = [];

    if (edit) {
      fs = fields.filter((f) => edit.includes(f.id));
    } else {
      fs = fields;
    }

    scheme = {};

    fs.forEach((f: UcodField) => {
      const label: string = f.label ? f.label : f.id.toUpperCase();
      if (f.required || f.pk) {
        scheme[f.id] = yup.string().required(`${label}을(를) 입력해주세요.`);
      } else {
        scheme[f.id] = yup.string().notRequired();
      }
    });
  }

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(yup.object().shape(scheme))
  });

  const fetchData = useCallback(async () => {
    try {
      if (!params.id) {
        return;
      }

      loadingOpen();

      if (fields.length === 0) {
        return;
      }

      const pkIds = fields.filter((v) => v.pk).map((v) => v.id);
      let p: { [key: string]: string } = {};

      pkIds.forEach((pkId: string, index: number) => {
        let key = `pk${index + 1}`;
        p[pkId as string] = params[key] as string;
      });

      const { result } = await api[apiKey].findByOne(p);
      const data: any = flatten(result.data);

      fields.forEach((f: UcodField) => {
        setValue(f.id, data[f.id]);
      });
    } catch (error) {
      // TODO Error 처리
    } finally {
      loadingClose();
    }
  },
    // eslint-disable-next-line
    [params, fields]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(
    () => {
      if (params.id) {
        const { fields, edit, disableEdit } = Type[params.id];

        if (disableEdit) {
          navigate(`/${params.id}`);
        }

        if (edit) {
          setFields(fields.filter((f) => edit.includes(f.id)));
        } else {
          setFields(fields);
        }
      }
    },
    // eslint-disable-next-line
    [params]
  );

  const handleClickList = () => {
    navigate(`/${params.id}`);
  };

  const handleClickShow = () => {
    let to: string = `/${params.id}/show/${params.pk1}`;

    if (params.pk2) {
      to += `/${params.pk2}`;
    }

    navigate(to)
  };

  const handleClickRemove = () => {

  };

  const handleSubmit = async (data: any) => {
    try {
      loadingOpen();
      const p = unflatten(data);
      await api[apiKey].edit(p);

      toastOpen('수정에 성공하였습니다.');
      handleClickShow();
    } catch (error: any) {
      console.log('EDIT >> 오류가 발생하였습니다.', error.message);
      handleClickShow();
    } finally {
      loadingClose();
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb />
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            mb: 2
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClickList}
            startIcon={<ListIcon />}
          >
            목록으로
          </Button>
          <div>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={handleClickShow}
              startIcon={<ShowIcon />}
              sx={{ mr: 1 }}
            >
              상세
            </Button>
            <Button
              variant="contained"
              color="error"
              disableElevation
              onClick={handleClickRemove}
              startIcon={<TrashIcon />}
            >
              삭제
            </Button>
          </div>
        </Box>
        <Box
          component="form"
          onSubmit={onSubmit(handleSubmit)}
        >
          {fields.map((f: UcodField) => (
            <FormControl
              key={`form-${f.id}`}
              fullWidth
              sx={{ mb: 2 }}
            >
              <FormLabel
                field={f}
                errors={errors[f.id] ? true : false}
              />
              {f.type === 'select' && (
                <Select
                  field={f}
                  register={register(f.id)}
                  error={errors[f.id] ? true : false}
                  helperText={errors[f.id]?.message as string}
                  defaultValue={''}
                  value={watch(f.id) || ''}
                  disabled={f.pk}
                />
              )}
              {f.type !== 'select' && (
                <TextField
                  field={f}
                  register={register(f.id)}
                  error={errors[f.id] ? true : false}
                  helperText={errors[f.id]?.message as string}
                  rows={f.type === 'textarea' ? 4 : 1}
                  disabled={f.pk}
                />
              )}
            </FormControl>
          ))}
          <Box
            sx={{
              mt: 4,
              mb: 6,
              textAlign: 'center'
            }}
          >
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disableElevation
              size="large"
              startIcon={<AddIcon />}
            >
              저장
            </Button>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};
