import React from 'react';
import { FindPasswordForm } from '@components/Auth/FindPasswordForm';
import { useNavigate } from 'react-router-dom';

export const FindPassword = (): JSX.Element => {
  const navigate = useNavigate();
  
  const handleFindPassword = () => {

  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <FindPasswordForm
      onFindPassword={handleFindPassword}
      onLogin={handleLogin}
      />
  )
};