import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Type, { UcodField, UcodFilter } from '@type';
import api from '@api';
import { Box, Checkbox, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { flatten } from 'flat';
import { formatDate } from '@util/format';
import { BreadCrumb } from '@components/Common/Breadcrumb';
import { FilterSidebar } from '@components/Common/List/FilterSidebar';
import { useLoading } from '@hook';
import { CertForceClose } from '@components/Cert/CertForceClose';
import { OrgForceClose } from '@components/Org/OrgForceClose';
import Paper from '@mui/material/Paper';

const components: {
  [key: string]: any
} = {
  certForceClose: CertForceClose,
  orgUseN: OrgForceClose
};

type CommonRowProp = {
  rowItem: any,
  headers: UcodField[],
  onClickRow: (rowItem: any) => void,
  useCheckbox: boolean,
  isSelected?: boolean,
  onClickCheckbox?: (rowItem: any, checked: boolean) => void,
};

/**
 * 공통 로우
 * @param prop
 * @returns 
 */
const CommonRow: React.FC<CommonRowProp> = (prop) => {
  const {
    rowItem,
    headers,
    onClickRow,
    useCheckbox,
    isSelected = false,
    onClickCheckbox
  } = prop;

  const handleClickRow = (e: any) => {
    e.preventDefault();
    onClickRow(rowItem);
  };

  const cellIds = headers.map((header) => header.id);
  const items: any = flatten(rowItem);

  const getValue = (cellId: string) => {
    const header = headers.find((h) => h.id === cellId);
    if (header?.format) {
      return header?.format(items[cellId]);
    }

    if (header?.type === 'date') {
      return formatDate(items[cellId]);
    }

    return items[cellId];
  };

  const handleCheckbox = (e: any, checked: boolean) => {
    e.stopPropagation();
    if (onClickCheckbox) {
      onClickCheckbox(rowItem, checked);
    }
  };

  return (
    <TableRow
      className="border-solid rounded border-black"
      hover
      sx={{ cursor: 'pointer' }}
    >
      {useCheckbox && (
        <TableCell
          padding={'checkbox'}
        >
          <Checkbox
            color="secondary"
            sx={{ py: 0 }}
            onChange={handleCheckbox}
            checked={isSelected}
          />
        </TableCell>
      )}
      {cellIds.map((cellId: string) => (
        <TableCell
          key={cellId}
          onClick={handleClickRow}
        >
          {getValue(cellId)}
        </TableCell>
      ))}
    </TableRow>
  );
};

/**
 * 관리자 공통 목록
 * @returns
 */
const CommonList = () => {
  const ROW_COUNT: number = 10;
  const params = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [items, setItems] = useState<any>([]);
  const [headers, setHeaders] = useState<UcodField[]>([]);
  const [fields, setFields] = useState<UcodField[]>([]);
  const [filterFields, setFilterFields] = useState<UcodFilter[]>([]);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const { loadingOpen, loadingClose } = useLoading();
  const [useCheckbox, setUseCheckbox] = useState<boolean>(false);
  const [selected, setSelected] = useState<readonly string[]>([]);

  let SelectEventComponent = null;
  console.log("CommonList", params)
  if (params.id) {
    const {
      useListCheckbox = false,
      listCheckboxComponentName
    } = Type[params.id];

    if (useListCheckbox && listCheckboxComponentName) {
      SelectEventComponent = components[listCheckboxComponentName];
    }
  }

  const fetchData = useCallback(
    async () => {
      try {
        if (!params.id) {
          return;
        }
        loadingOpen();
        setSelected([]);
        // param의 id로 api 데이터를 불러온다.
        const key = params.id + 'Api';
        const { result } = await api[key].find(page, filters);
        setCount(result.count);
        setItems(result.data);
      } catch (error: any) {
        // TODO 오류 처리
      } finally {
        loadingClose();
      }
    },
    // eslint-disable-next-line
    [params, page, filters]
  );

  /**
   * 필터 생성
   */
  const fetchFilter = useCallback(
    async (filters?: UcodFilter[]) => {
      if (!filters) {
        setFilterFields([]);
        return;
      }

      const newFilters: UcodFilter[] = [];

      filters.forEach(async (filter) => {
        // 필터의 속성이 string이라면 데이터 요청을 한다.
        if (typeof filter.reference === 'string') {
          const key: string = `${filter.id}Api`;
          const { result } = await api[key].findAll();

          const { fields } = Type[filter.id];
          const pk = fields.find((f) => f.pk);

          if (pk) {
            const reference = result.data.map((v: any) => {
              return {
                value: v[pk.id],
                // 이름은 name, title로 구성하되, 전부 존재하지 않을 경우 id로 한다.
                label: v.name || v.title || v[pk.id]
              }
            });

            filter.reference = reference;
          } else {
            filter.reference = [];
          }
        }

        newFilters.push(filter);
      });

      setFilterFields(newFilters);
    },
    []
  )

  useEffect(() => {
    if (params.id) {
      const key = params.id;
      const {
        fields,
        list,
        filters,
        useListCheckbox = false,
      } = Type[key];
      setFields(fields);
      if (!list) {
        setHeaders(fields)
      } else {
        setHeaders(
          fields.filter((value) => list.includes(value.id))
        )
      }

      setFilters({});
      fetchFilter(filters);
      setUseCheckbox(useListCheckbox);
    }
  }, [params, fetchFilter]);

  useEffect(() => { fetchData() }, [fetchData])

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
  };

  /**
   * PK키로 SHOW 페이지로 할 수 있도록 시킨다.
   * @param item
   */
  const handleClickRow = (item: any) => {
    const pkIds = fields.filter((v) => v.pk).map((value) => encodeURIComponent(item[value.id]));
    let p = `/${params.id}/show/${pkIds.join('/')}`;
    navigate(p);
  };

  const handleAllClick = (e: any, checked: boolean) => {
    const pkIds = fields.filter((v) => v.pk);
    if (pkIds.length > 1 || pkIds.length < 1) {

      return;
    }

    if (checked) {
      const id = pkIds[0].id;
      const newSelected = [...items].map((v: any) => v[id]);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const isSelected = (item: any): boolean => {
    const pkIds = fields.filter((v) => v.pk);
    if (pkIds.length > 1 || pkIds.length < 1) {
      console.log('ONLY PK ONE');
      return false;
    }

    const id = pkIds[0].id;
    return selected.includes(item[id]);
  };

  const handleClickCheckbox = (item: any) => {
    const pkIds = fields.filter((v) => v.pk);
    if (pkIds.length > 1 || pkIds.length < 1) {
      console.log('ONLY PK ONE');
      return false;
    }

    const id = pkIds[0].id;
    const itemValue = item[id];

    const index: number = selected.findIndex((v) => v === itemValue);

    if (index < 0) {
      setSelected((selected) => [...selected, itemValue]);
    } else {
      setSelected((selected) => selected.filter((_, i: number) => index !== i));
    }
  };

  const handleSelectEventCompleted = () => {
    setSelected([]);
    fetchData();
  }

  return (
    <React.Fragment>
      <BreadCrumb />
      {/* CONTENTS */}
      <Box className="flex-1 m-4" >
        <Box className="mb-4" sx={{ display: 'flex', width: '100%' }}>
          {SelectEventComponent !== null && (
            <SelectEventComponent
              selected={selected}
              onCompleted={handleSelectEventCompleted}
            />
          )}
          {filterFields.length > 0 && (
            <FilterSidebar
              filters={filterFields}
              buttonProps={{
                sx: {
                  ml: 'auto'
                }
              }}
              onFiltering={(filters) => setFilters(filters)}
            />
          )}
        </Box>
        <TableContainer component={Paper} className="-mb-12 " sx={{ width: '100%' }}>
          <Table
            className='border-solid'
            sx={{ mb: 8 }}>
            <TableHead>
              <TableRow>
                {useCheckbox && (
                  <TableCell
                    padding={'checkbox'}
                  >
                    <Checkbox
                      sx={{ py: 0 }}
                      color="secondary"
                      onChange={handleAllClick}
                      checked={
                        (selected.length > 0 && selected.length === items.length)
                      }
                      indeterminate={
                        (selected.length > 0 && selected.length < items.length)
                      }
                    />
                  </TableCell>
                )}
                {headers.map((header: any) => (
                  <TableCell key={header.id}>
                    {header.label ? header.label : header.id.toUpperCase()}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {count === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    sx={{
                      textAlign: 'center'
                    }}>
                    데이터가 없습니다.
                  </TableCell>
                </TableRow>
              )}
              {items.map((item: any, index: number) => (
                <CommonRow
                  key={index}
                  rowItem={item}
                  headers={headers}
                  onClickRow={handleClickRow}
                  useCheckbox={useCheckbox}
                  isSelected={isSelected(item)}
                  onClickCheckbox={handleClickCheckbox}
                />
              ))}
            </TableBody>
          </Table>

        </TableContainer>
        {/* PAGINATION */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Pagination
            count={Math.ceil(count / ROW_COUNT)}
            page={page}
            color="secondary"
            showFirstButton
            showLastButton
            onChange={handleChangePage}
          />
        </Box>
      </Box>
    </React.Fragment>
  )
};
export default CommonList