
import { Outlet } from 'react-router-dom';
import { Box, Drawer, Toolbar } from '@mui/material'
import { SIDEBAR_WIDTH } from '@const';
import { Header } from '@components/Header/Header';
import { MainMenu } from '@components/Common/MainMenu';

export const Layout = (): JSX.Element => {
  return (
    <Box className="h-full overflow-y-hidden"
      sx={{ display: 'flex' }}>
      <Header />
      <Drawer
        variant="permanent"
        sx={{
          width: SIDEBAR_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: SIDEBAR_WIDTH,
            boxSizing: 'border-box'
          }
        }}
        open
        anchor="left"
      >
        {/* 사이드바 상단 여백 */}
        <Toolbar />
        {/* 사이드바 메뉴 */}
        <MainMenu />
      </Drawer>
      { /** 바디 */}
      <Box
        component="main"
        className="h-screen bg-gray-50"
        sx={{
          flexGrow: 1,
          width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
        }}>
        <Toolbar />
        {/* 주영역 스크롤등을 여기에서 처리해준다. 최대 크기등... 
        내용은 라우터에서 결정한다.
        */}
        <Box className="h-screen overflow-y-auto overflow-x-auto">
          <Outlet />
        </Box>
      </Box>
    </Box >
  )
};
