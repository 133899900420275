import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import theme from '@style/theme';
import { FolderTreeView } from './FolderTreeView';
import { DOC_UPLOAD_ID } from '@const';
import { useFetchTree, useProgress } from '@hook';
import { useDropzone } from 'react-dropzone';
import {
  Clear as RemoveIcon
} from '@mui/icons-material'
import { driveApi } from '@api';
import { useNavigate } from 'react-router-dom';

type DocumentUploadModalProps = DialogProps & {
  onCreated: (id: string, result?: any) => void;
  onCancel: (id: string) => void;
  onFailed?: (id: string) => void;
  onProcess?: (id: string) => void;
  targetType: "doc" | "my" | "trash"

};

/**
 *
 * @param props
 * @returns
 */
export const DocumentUploadModal: React.FC<DocumentUploadModalProps> = (props): JSX.Element => {
  const { onCreated, onCancel, ...others } = props;

  const { progressOpen, progressClose } = useProgress();
  console.log("Dock Upload")
  const { tree, reloadTree } = useFetchTree(props.targetType);
  const [target, setTarget] = useState<string>('');
  const navigate = useNavigate();
  const [files, setFiles] = useState<File[]>([]);

  const {
    acceptedFiles,
    getInputProps,
    getRootProps,
    open,
  } = useDropzone({
    noClick: true,
    accept: {
      'application/pdf': ['.pdf']
    }
  });

  useEffect(() => {
    setFiles((files) => [...files, ...acceptedFiles]);
  }, [acceptedFiles]);

  useEffect(() => {
    if (others.open) {
      reloadTree();
    }
  },
    // eslint-disable-next-line
    [others.open]);

  const handleOk = async () => {
    try {
      progressOpen('업로드', '문서를 업로드 하고 있습니다.');
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      })

      console.log(formData);
      formData.append('target', target);
      formData.append('type', props.targetType);

      await driveApi.uploads(formData);
      onCreated(DOC_UPLOAD_ID);
      navigate(`/doc?q=${target.split('/').slice(1).join('/')}`);
    } catch (error: any) {

    } finally {
      progressClose();
    }
  };

  const handleCancel = () => {
    onCancel(DOC_UPLOAD_ID);
  }

  const handleNodeSelect = (e: any, nodeIds: string) => {
    console.log(nodeIds, tree);
    setTarget(nodeIds);
  };

  const handleClickOpen = (e: any) => {
    e.preventDefault();
    open();
  };

  const handleRemoveItem = (file: File) => {
    const index = files.findIndex((f) => f === file);
    const newFiles = [...files]
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      {...others}
    >
      <DialogTitle sx={{ fontWeight: 700 }}>
        업로드
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <div></div>
            <Button
              type="button"
              variant="contained"
              disableElevation
              color="secondary"
              size="small"
              onClick={handleClickOpen}>
              문서 선택
            </Button>
          </Grid>
          <Grid xs={5}>
            {tree && (
              <FolderTreeView
                sx={{
                  height: 240,
                  maxHeight: 240,
                  overflowY: 'auto',
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '8px',
                  padding: '4px'
                }}
                driveTree={tree}
                onNodeSelect={handleNodeSelect}
              />
            )}
          </Grid>
          <Grid xs={7}>
            <List
              sx={{
                height: 240,
                maxHeight: 240,
                overflowY: 'auto',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '8px',
                padding: '4px'
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {files.length === 0 && (
                <Typography component="li" sx={{ textAlign: 'center' }}>업로드 하실 문서를 선택해주세요</Typography>
              )}
              {files.map((file, index) => (
                <>
                  <ListItem
                    key={`File-${index}`}
                    secondaryAction={
                      <IconButton edge="end"
                        onClick={() => handleRemoveItem(file)}>
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    }>
                    <ListItemText>
                      {file.name}
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={handleCancel}>
          취소
        </Button>
        <Button
          color="secondary"
          onClick={handleOk}>
          업로드
        </Button>
      </DialogActions>
    </Dialog>
  )
};