import React, { useState } from 'react';
import { TRASH_EMPTY_ID, TRASH_RESTORE_ID } from '@const';
import { useFetchDrive, useToast } from '@hook';
import { Box, } from '@mui/material';
import { DriveDto } from '@dto/DriveDto';
import { TrashEmptyModal } from '@components/Folder/TrashEmptyModal';
import { TrashRestoreModal } from '@components/Folder/TrashRestoreModal';
import { FolderList } from '@components/Folder/FolderList';
import { FolderToolbar } from '@components/Folder/FolderToolbar';




/**
 *
 * @returns
 */
export const TrashList = () => {
  const [openEmpty, setOpenEmpty] = useState<boolean>(false);
  const [openRestore, setOpenRestore] = useState<boolean>(false);
  const { toastOpen } = useToast();
  const { driveList, folderMove, reloadData } = useFetchDrive();
  const [selected, setSelected] = useState<DriveDto[]>([]);

  /**
   *
   * @param id
   */
  const handleOpen = (id: string) => {
    if (id === TRASH_EMPTY_ID) {
      setOpenEmpty(true);
    } else if (id === TRASH_RESTORE_ID) {
      setOpenRestore(true);
    }
  };

  /**
   * 모달 닫기
   * @param id
   */
  const handleCancel = (id: string) => {
    if (id === TRASH_EMPTY_ID) {
      setOpenEmpty(false);
    } else if (id === TRASH_RESTORE_ID) {
      setOpenRestore(false);
    }
  };

  /**
   * 처리 성공
   * @param id
   */
  const handleCreated = (id: string) => {
    let message: string = '';

    if (id === TRASH_EMPTY_ID) {
      setOpenEmpty(false);
      message = '휴지통을 비었습니다.';
    } else if (id === TRASH_RESTORE_ID) {
      setOpenRestore(false);
      message = '복원을 완료하였습니다.';
    }

    toastOpen(message);
    reloadData();
  };

  /**
   * 한가지 아이템 선택
   * @param data
   */
  const handleSelected = (data: DriveDto) => {
    const index: number = selected.findIndex((v) => v.path === data.path);

    if (index < 0) {
      setSelected((selected) => [...selected, data]);
    } else {
      setSelected((selected) => selected.filter((_, i: number) => i !== index))
    }
  };

  const handleFolderMove = (folder: DriveDto) => {
    folderMove(folder.path);
  };

  /**
   * 전체 선택
   * @param checked
   */
  const handleSelectedAll = (checked: boolean) => {
    if (checked) {
      setSelected([...driveList].filter((value) => value.type !== 'none'));
    } else {
      setSelected([]);
    }
  };

  return (
    <Box sx={{
      position: 'realtive',
      height: '100%',
      width: '100%'
    }}>
      <FolderToolbar
        onMenuClick={handleOpen}
      />
      {/* <FolderSearchToolbar
        folderName="휴지통"
      /> */}
      <Box
        className="m-4 v-full"
        sx={{
          display: 'flex',
          height: `calc(100% - 190px)`
        }}>
        <FolderList

          useCheckbox

          driveList={driveList}
          onFolderMove={handleFolderMove}
          onSelected={handleSelected}
          onSelectedAll={handleSelectedAll}
        />
        <TrashEmptyModal
          selected={selected}
          open={openEmpty}
          onCreated={handleCreated}
          onCancel={handleCancel}
        />
        <TrashRestoreModal
          selected={selected}
          open={openRestore}
          onCreated={handleCreated}
          onCancel={handleCancel}
        /></Box>
    </Box>
  )
};