import React from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import type { TableCellProps } from '@mui/material';

export type HeaderCell = TableCellProps & {
  id: string;
  label: string;
  format?: Function;
};

export interface TableHeaderProps {
  // 헤더 셀 목록
  headerCells: HeaderCell[];
  // 체크박스 사용 여부
  useCheckbox?: boolean;
  numSelected: number;
  // 현재 페이지 전체 개수
  rowCount: number;
  // 모든 아이템 개수
  totalRowCount: number;
  // 전체 선택 이벤트
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};


export const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const {
    headerCells,
    // eslint-disable-next-line
    numSelected,
    // eslint-disable-next-line
    rowCount,
    // eslint-disable-next-line
    totalRowCount,
    useCheckbox = false,
    onSelectAllClick
  } = props;

  return (
    <TableRow>
      {/* CHECKBOX */}
      {useCheckbox && (
        <TableCell
          padding="checkbox"
          >
            <Checkbox
              color="secondary"
              indeterminate={numSelected > 0 && numSelected < (rowCount > totalRowCount ? totalRowCount : rowCount)}
              checked={rowCount > 0 && numSelected === (rowCount > totalRowCount ? totalRowCount : rowCount)}
              onChange={onSelectAllClick}
              sx={{ padding: 0 }}
              />
        </TableCell>
      )}
      {headerCells.map((headerCell) => {
        const { id, label, format, ...other } = headerCell;

        return (
          <TableCell
            key={id}
            {...other}
            >
              {label}
          </TableCell>
        );
      })}
    </TableRow>
  )
};
