import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import type { DialogProps } from '@mui/material';
import { useAlert, useProgress } from '@hook';
import { TRASH_EMPTY_ID } from '@const';
import { DriveDto } from '@dto/DriveDto';
import { driveApi } from '@api';

/**
 * onProcess => 처리 시작, 처리 중
 * onCreated => 처리 완료
 * onFailed => 처리 실패
 */
type TrashEmptyModalProps = DialogProps & {
  selected?: DriveDto[],
  onCreated: (id: string, result?: any) => void;
  onCancel: (id: string) => void;
  onFailed?: (id: string) => void;
  onProcess?: (id: string) => void;
};

/**
 * 휴지통 비우기 모달
 * @param props
 * @returns 
 */
export const TrashEmptyModal: React.FC<TrashEmptyModalProps> = (props) => {
  const { selected, onCreated, onCancel, onProcess, ...other } = props;

  const { alertOpen } = useAlert();
  const { progressOpen, progressClose } = useProgress();

  const handleOk = async () => {
    try {
      progressOpen(
        '휴지통',
        '삭제하고 있습니다.'
      );

      if (!selected || selected.length === 0) {
        throw new Error('선택된 파일 혹은 디렉토리가 없습니다.');
      }

      console.log(`trash/${selected[0].path}`);

      await Promise.all(
        selected.map(async (value) => await driveApi.remove([`${value.path}`], value.driveType))
      );
      onCreated(TRASH_EMPTY_ID);
    } catch (error: any) {
      alertOpen({
        title: '오류',
        content: (<Box>
          휴지통으로 파일을 삭제하는 중 오류가 발생하였습니다..<br />
          {error.message}
        </Box>),
        onOk () {
          onCancel(TRASH_EMPTY_ID);
        }
      });
    } finally {
      progressClose();
    }
  };

  const handleCancel = () => {
    onCancel(TRASH_EMPTY_ID);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        {...other}>
        <DialogTitle>휴지통</DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택하신 항목을 휴지통에서 삭제하시겠습니까?<br />(복원을 할 수 없습니다.)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={handleCancel}
          >
            취소
          </Button>
          <Button
            color="secondary"
            onClick={handleOk}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
};
