import { useLocation } from 'react-router-dom'
import {
  CloudUploadOutlined as UploadIcon,
  CreateNewFolderOutlined as NewFolderIcon,
  DriveFileMoveOutlined as MoveFolderIcon,
  DeleteOutline as TrashIcon,
  CreateOutlined as NameChangeIcon,
  RefreshOutlined as RestoreIcon,
} from '@mui/icons-material'
import { useEffect, useState } from 'react';
import {
  DOC_MOVE_ID,
  DOC_NAME_CHANGE_ID,
  DOC_NEW_FOLDER_ID,
  DOC_TRASH_ID,
  DOC_UPLOAD_ID,
  TRASH_EMPTY_ID,
  TRASH_RESTORE_ID
} from '@const';


const DOC_MENUS = [
  {
    id: DOC_UPLOAD_ID,
    label: '업로드',
    icon: <UploadIcon />
  },
  {
    id: DOC_NEW_FOLDER_ID,
    label: '폴더 생성',
    icon: <NewFolderIcon />
  },
  {
    id: DOC_MOVE_ID,
    label: '이동',
    icon: <MoveFolderIcon />
  },
  {
    id: DOC_TRASH_ID,
    label: '휴지통',
    icon: <TrashIcon />
  },
  {
    id: 'divider',
    label: null,
    icon: null
  },
  {
    id: DOC_NAME_CHANGE_ID,
    label: '이름 변경',
    icon: <NameChangeIcon />
  }
];

const TRASH_MENUS = [
  {
    id: TRASH_EMPTY_ID,
    label: '비우기',
    icon: <TrashIcon />
  },
  {
    id: TRASH_RESTORE_ID,
    label: '복원하기',
    icon: <RestoreIcon />
  }
]

/**
 * pathname과 선택된 아이템 갯수에 맞춰 toolbar 메뉴를 변경
 * @param selected
 * @returns
 */
function useDocToolbarMenus (
  selected: string //'none' | 'multi' | 'single' = 'none'
) {
  const { pathname } = useLocation();
  const [menus, setMenus] = useState<any>([]);

  useEffect(() => {
    if (pathname === '/trash') {
      setMenus(() => TRASH_MENUS);
    } else if (pathname === '/doc') {
      let endIndex: number = 0;

      if (selected === 'none') {
        endIndex = 2;
      } else if (selected === 'single') {
        endIndex = 6;
      } else if (selected === 'multi') {
        endIndex = 4;
      }

      setMenus(() => [...DOC_MENUS].slice(0, endIndex));
    }
  }, [pathname, selected]);

  return [menus];
}

export default useDocToolbarMenus;