import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from './useUser';
import { TOKEN_KEY } from '@const';
import { UserDto } from '@dto/UserDto';
import { authApi } from '@api';

/**
 * 세션 체크 Hook
 */
function useCheckSession () {
  const { pathname } = useLocation();
  const { user, dispatchUser } = useUser();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    if (
      /\/login/.test(pathname) ||
      /\/findpassword/.test(pathname)
    ) {
      return;
    }

    try {
      const strToken: string | null = localStorage.getItem(TOKEN_KEY);

      if (!strToken) {
        throw new Error('인증이 필요합니다');
      }

      let u: UserDto | undefined;

      if (!user) {
        // store에 저장된 user가 없다면 me에 저장시킨다.
        const { result } = await authApi.me();
        u = { ...result };
        dispatchUser(u);
      } else {
        u = { ...user };
      }
    } catch (error: any) {
      navigate(`/login?e=${Math.random().toString(36).substring(2, 12)}`)
    }
  },
    // eslint-disable-next-line
    [pathname]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
};

export default useCheckSession;