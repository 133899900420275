import React, { useState } from 'react';
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import {
  MoreHoriz as MoreIcon,
  DriveFileMoveOutlined as MoveIcon,
  DriveFileRenameOutline as RenameIcon,
  DeleteOutline as TrashIcon
} from '@mui/icons-material';
import { DOC_MOVE_ID, DOC_NAME_CHANGE_ID, DOC_TRASH_ID } from '@const';

type DocumentMenuProps = {
  dataId: string;
  onClickMenu: (eventId: string, id: string) => void;
};

/**
 * 문서 상세 메뉴
 * @param props
 * @returns
 */
export const DocumentMenu: React.FC<DocumentMenuProps> = (props): JSX.Element => {
  const { dataId, onClickMenu } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    const { eventId } = event.currentTarget.dataset;
    onClickMenu(eventId as string, dataId);
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        size={'small'}
      >
        <MoreIcon fontSize="small" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <List dense className="border-solid rounded border-black">
          <ListItem>
            <ListItemButton
              data-event-id={DOC_MOVE_ID}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <MoveIcon />
              </ListItemIcon>
              <ListItemText>이동</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              data-event-id={DOC_NAME_CHANGE_ID}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <RenameIcon />
              </ListItemIcon>
              <ListItemText>이름변경</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              data-event-id={DOC_TRASH_ID}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <TrashIcon />
              </ListItemIcon>
              <ListItemText>휴지통</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </React.Fragment>
  )
};