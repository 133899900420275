import ResultDto from '@dto/ResultDto';
import instance from './instance';
import ListResultDto from '@dto/ListResultDto';
import { CertDto } from '@dto/CertDto';
import UpdateResultDto from '@dto/UpdateResultDto';
import DetailResultDto from '@dto/DetailResultDto';

/**
 *
 * @param page
 * @param org
 * @param expired
 * @returns
 */
export const find = async (
  page: number,
  { org, expired }: {
    org?: readonly string[],
    expired?: readonly string[]
  }
): Promise<ResultDto<ListResultDto<CertDto>>> =>
  await instance.get('/cert', {
    params: {
      page,
      org,
      expired
    }
  });

export const expire = async (certId: readonly string[]): Promise<ResultDto<UpdateResultDto>> =>
  await instance.patch('/cert/expire', { certId });

/**
 * 상세 정보를 가져온다.
 * @param certId
 * @returns
 */
export const findByCertId = async (certId: string):
  Promise<ResultDto<DetailResultDto<CertDto>>> =>
  await instance.get(`/cert/${certId}`);


/**
 * 상세 정보를 가져온다.
 * @param certId
 * @returns
 */
export const findByOne = async ({ certId }: { certId: string}):
  Promise<ResultDto<DetailResultDto<CertDto>>> =>
  await instance.get(`/cert/${certId}`);