import React from 'react';
import { Global } from '@emotion/react';
import type {Interpolation, Theme} from '@emotion/react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'pretendard/dist/web/static/pretendard.css';

import '@fontsource/noto-sans-kr/300.css';
import '@fontsource/noto-sans-kr/400.css';
import '@fontsource/noto-sans-kr/500.css';
import '@fontsource/noto-sans-kr/700.css';

const styles: Interpolation<Theme> = {
  body: {
    margin: 0,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Apple SD Gothic Neo"',
      '"Pretendard Variable"',
      'Pretendard',
      'Roboto',
      '"Noto Sans KR"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  }
};

/**
 * 글로벌 스타일 지정
 * @returns
 */
const GlobalStyles = (): JSX.Element => {
  return (
    <Global
      styles={styles}
      />
  )
};

export default GlobalStyles;