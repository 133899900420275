import { useAppDispatch, useAppSelector } from '@store/hook';
import { open, close, selectToast } from '@store/toast';

/**
 * Toast 상태 처리
 * @returns
 */
function useToast () {
  const dispatch = useAppDispatch();
  const toast = useAppSelector(selectToast);

  const toastOpen = (message: string) => {
    dispatch(
      open({
        isOpen: true,
        message
      })
    );
  };

  const toastClose = () => {
    dispatch(close())
  };

  return {
    toast,
    toastOpen,
    toastClose
  };
};

export default useToast;
