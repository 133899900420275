import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import mem from 'mem';
import {
  API_URL,
  TOKEN_KEY
} from '@const';
import { TokenDto } from '@dto/TokenDto';

const instance: AxiosInstance = axios.create({
  baseURL: API_URL,
});

instance.defaults.paramsSerializer = params => qs.stringify(params);

const getRefreshToken = mem(
  async (): Promise<TokenDto | void> => {
    try {
      const storageToken: TokenDto = JSON.parse(localStorage.getItem(TOKEN_KEY) || '{}');

      const response: { status: number; result: TokenDto } = await instance.post(
        '/auth/refreshToken',
        {
          Headers: `Bearer ${storageToken.refresh_token}`,
        },
      );

      localStorage.setItem(
        TOKEN_KEY,
        JSON.stringify({
          access_token: response.result.access_token,
          refresh_token: response.result.refresh_token,
        }),
      );

      return response.result;
    } catch (error) {
      localStorage.removeItem(TOKEN_KEY);
    }
  },
  { maxAge: 1000 },
);

instance.interceptors.request.use(
  (config: any) => {
    let useAuthorization: boolean = false;

    if (config.url) {
      if (
        /\/login/g.test(config.url) ||
        /\/changepasswd/g.test(config.url) ||
        /\/register/g.test(config.url)
      ) {
        useAuthorization = false;
      } else {
        useAuthorization = true;
      }
    }

    if (useAuthorization && config.headers && config.url) {
      const token: TokenDto = JSON.parse(localStorage.getItem(TOKEN_KEY) || '{}');
      if (/\/refreshToken/g.test(config.url)) {
        config.headers.Authorization = `Bearer ${token.refresh_token}`;
      } else {
        config.headers.Authorization = `Bearer ${token.access_token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error: any) => {
    const { config, response } = error;

    if (config && config.url && response && response.status) {
      // console.log("axios intercept", response.status, config.url);
      if (/\/refreshToken/g.test(config.url)) {
        // refreshToken을 실행 후 오류가 발생하면 reject를 시킨다.
        return Promise.reject(response.data);
      }

      if (response.status === 401) {
        // console.log('UPDATE refresh_token');
        const token: TokenDto | void = await getRefreshToken();
        // console.log('accessToken', token);

        if (token) {
          if (config.headers) {
            config.headers.Authorization = `Bearer ${token.access_token}`;
          }

          return instance(config);
        } else {
          // TOKEN이 없을 시에는 signin으로 e 쿼리 데이터를 포함해 이동시킨다.
          window.location.href = `/login?e=${Math.random().toString(36).substring(2, 12)}`;
        }
      } else if (response.status === 500) {
        // LOGOUT일 경우에는 일반 에러 처리로 간주한다.
        if (process.env.NODE_ENV === 'production' && !/\/logout/g.test(config.url)) {
          window.location.href = '/error';
        } else {
          return Promise.reject(response.data);
        }
      } else {
        return Promise.reject(response.data);
      }
    }

    return Promise.reject(error.response.data);
  },
);

export default instance;