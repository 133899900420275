import { UserDto } from '@dto/UserDto';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';

export interface UserState {
  user?: UserDto
};

const initialState: UserState = {
  user: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserDto>) => {
      state.user = { ...action.payload };
    },
    removeUser: (state) => {
      state.user = undefined;
    },
  },
});

export const selectUser = (state: RootState) => state.user;

export const { setUser, removeUser } = userSlice.actions;
export default userSlice.reducer;