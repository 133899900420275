import { ROLE, USER_STATE } from "./code";

export const API_URL: string = process.env.REACT_APP_API_URL || 'https://more-dms-api.ucod.kr/api/v1';
export const TOKEN_KEY: string = process.env.REACT_APP_TOKEN_KEY || 'more-dms';

export const SIDEBAR_WIDTH: number = 240;
export const ROOT_PATH: string = process.env.REACT_APP_ROOT_PATH || '/doc';

export const DOC_UPLOAD_ID: string = 'upload';
export const DOC_NEW_FOLDER_ID: string = 'new-folder';
export const DOC_MOVE_ID: string = 'move';
export const DOC_TRASH_ID: string = 'trash';
export const DOC_NAME_CHANGE_ID: string = 'name-change';
export const DOC_SELECTED: string = 'multi';
export const TRASH_EMPTY_ID: string = 'empty';
export const TRASH_RESTORE_ID: string = 'restore';

export const FILTER_YN = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' }
];

// 로그 작업 종류
export const FILTER_LOG_TYPE = [
  { label: 'PDF 열람', value: 'R' },
  { label: 'PDF 출력', value: 'P' },
  { label: 'PDF 다운로드', value: 'B' },
  { label: 'TTS', value: 'T' },
];

// 로그 상태
export const FILTER_LOG_STATUS = [
  { label: '오류 (9)', value: '9' },
  { label: '오류 (0)', value: '0' },
  { label: '정상 (3)', value: '3' }
]

export const FILTER_PRINTER_DELIST_TARGET = [
  { label: 'NAME', value: 'NAME' },
  { label: 'PORT', value: 'PORT' },
  { label: 'DRIVER', value: 'DRIVER' },
];

export const FILTER_ADMIN_ROLE = [
  { label: '시스템관리자', value: ROLE.SYSTEM.toString() },
  { label: '로그관리자', value: ROLE.LOG.toString() },
  { label: '사용자', value: ROLE.USER.toString() },
];

export const FILTER_ADMIN_STATUS = [
  { label: '사용중', value: USER_STATE.ACTIVATE },
  { label: '이용중지', value: USER_STATE.STOP },
  { label: '삭제', value: USER_STATE.REMOVE },
];

export const USE_YN = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' }
];

export const TARGET_TYPE = [
  { label: 'NAME', value: 'NAME' },
  { label: 'PORT', value: 'PORT' },
  { label: 'DRIVER', value: 'DRIVER' },
]

export const ADMIN_STATUS = [
  { label: '사용중', value: USER_STATE.ACTIVATE },
  { label: '이용중지', value: USER_STATE.STOP },
  { label: '삭제', value: USER_STATE.REMOVE },
];

export const ADMIN_ROLE = [
  { label: '시스템관리자', value: ROLE.SYSTEM.toString() },
  { label: '로그관리자', value: ROLE.LOG.toString() },
  { label: '사용자', value: ROLE.USER.toString() },
];

export const API = {
  AUTH: {
    LOGIN: 'auth.login'
  }
};

