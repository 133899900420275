import { USE_YN } from '@const';
import { UcodAdmin } from '@type';

const LiveUpdate: UcodAdmin = {
  id: 'liveUpdate',
  label: '프린트 앱 관리',
  fields: [
    {
      id: 'version',
      label: '앱 버전',
      pk: true,
      type: 'string'
    },
    {
      id: 'ips',
      pk: true,
      required: true,
      type: 'string'
    },
    {
      id: 'contents',
      type: 'string'
    },
    {
      id: 'fileType',
      label: '파일 타입',
      type: 'string'
    },
    {
      id: 'description',
      label: '설명',
      type: 'textarea'
    },
    {
      id: 'useYn',
      label: '사용여부',
      type: 'select',
      required: true,
    },
    {
      id: 'cdate',
      label: '생성일',
      type: 'date'
    },
    {
      id: 'udate',
      label: '최종 수정일',
      type: 'date'
    },
  ],
  list: [
    'version',
    'ips',
    'fileType',
    'useYn',
    'udate'
  ],
  create: [
    'version',
    'ips',
    'fileType',
    'useYn',
    'description'
  ],
  show: [
    'version',
    'ips',
    'fileType',
    'useYn',
    'description',
    'contents',
    'udate'
  ],
  edit: [
    'version',
    'ips',
    'fileType',
    'useYn',
    'description'
  ],
  reference: [
    {
      id: 'useYn',
      reference: USE_YN
    }
  ]
};

export default LiveUpdate;