import React from 'react';
import { TreeView, TreeItem, TreeItemProps, TreeViewProps } from '@mui/lab';
import {
  FolderOutlined as FolderIcon,
  FolderOpenOutlined as FolderOpenIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { DriveDto } from '@dto/DriveDto';

type FolderTreeViewProps = TreeViewProps<any> & {
  driveTree?: DriveDto
};

/**
 * 스타일 지정
 */
const FolderTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} />
))(() => ({
  [`&`]: {
    marginTop: '4px',
    marginBottom: '4px'
  },
  [`& .MuiTreeItem-content`]: {
    paddingTop: '4px',
    paddingBottom: '4px',
    borderRadius: '8px',
    width: 'auto',
    [`&:hover`]: {
      backgroundColor: '#F3F6F9',
    },
    [`&.Mui-selected`]: {
      backgroundColor: '#F0F7FF',
      color: '#0072E5',
      [`&:hover`]: {
        backgroundColor: 'rgba(0, 127, 255, 0.12)',
        color: 'inherit'
      }
    },
    [`& .MuiTreeItem-label`]: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.43,
    }
  }
}));

const renderTree = (nodes: DriveDto) => (
  <FolderTreeItem
    key={nodes.path}
    nodeId={`${nodes.path}`}
    label={

      nodes.path === '/'
        ? '내 문서'
        : nodes.name
    }
  >
    {
      Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null
    }
  </FolderTreeItem>
);

/**
 * 폴더 트리뷰
 * @returns
 */
export const FolderTreeView: React.FC<FolderTreeViewProps> = (props) => {
  const { driveTree, ...others } = props;

  return (
    <TreeView
      defaultCollapseIcon={<FolderOpenIcon />}
      defaultExpandIcon={<FolderIcon />}
      defaultEndIcon={<FolderIcon />}
      {...others}
    >
      {driveTree && (
        renderTree(driveTree)
      )}
    </TreeView>
  )
};
