import ResultDto from '@dto/ResultDto'
import instance from './instance'
import ListResultDto from '@dto/ListResultDto'
import { AnnotDto } from '@dto/AnnotDto'
import DetailResultDto from '@dto/DetailResultDto';

export const find = async (page: number):
  Promise<ResultDto<ListResultDto<AnnotDto>>> =>
  await instance.get(`/annot`, {
    params: {
      page
    }
  });

export const findByDocHash = async (docHash: string, userId: string):
  Promise<ResultDto<DetailResultDto<AnnotDto>>> =>
  await instance.get(`/annot/${docHash}/${userId}`);


export const findByOne = async ({ docHash, userId }: {docHash: string, userId: string}):
  Promise<ResultDto<DetailResultDto<AnnotDto>>> =>
  await instance.get(`/annot/${encodeURIComponent(docHash)}/${userId}`);