import { useAppDispatch, useAppSelector } from '@store/hook';
import { open, close, ok, selectConfirm } from '@store/confirm';
import React from 'react';

/**
 * Toast 상태 처리
 * @returns
 */
function useConfirm () {
  const dispatch = useAppDispatch();
  const confirm = useAppSelector(selectConfirm);

  const confirmOpen = (payload: {
    title: string,
    content: React.ReactNode,
    onOk?: () => void,
    onCancel?: () => void,
    okTitle?: string,
    cancelTitle?: string,
  }) => {
    dispatch(
      open({
        isOpen: true,
        ...payload
      })
    );
  };

  const confirmClose = () => {
    dispatch(close())
  };

  const confirmOk = () => {
    dispatch(ok());
  }

  return {
    confirm,
    confirmOpen,
    confirmClose,
    confirmOk
  };
};

export default useConfirm;
