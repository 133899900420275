import { Box, Checkbox, Divider, FormControlLabel, List, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import {
  ImportContactsOutlined as OpenIcon,
  DriveFileMoveOutlined as MoveIcon,
  DriveFileRenameOutline as RenameIcon,
  DeleteOutline as TrashIcon,
} from '@mui/icons-material';
import { SIDEBAR_WIDTH } from '@const';
import React, { useCallback, useEffect, useState } from 'react';
import { DriveDto } from '@dto/DriveDto';
import { DocumentPreview } from './DocumentPreview';
import { driveApi } from '@api';
import useSeletedStore from '../../store/mystore';

type FolderSidebarProps = {
  open?: boolean,
  onMove: () => void,
  openDefault?: () => void,
  onRename: () => void,
  onTrash: () => void,
}


interface DRMOption {
  noCache: boolean
  link: boolean
  debugText: boolean
  debugAccess: boolean
  zoom: boolean
  nosmooth: boolean
  orgimage: boolean
  noexpire: boolean //useLimit == -90

}
/**
 *
 * @returns
 */
export const FolderSidebar: React.FC<FolderSidebarProps> = (props) => {
  const { open, onMove, onRename, onTrash } = props;
  const [page, setPage] = useState<number>(0);
  const [reqOption, setReqOption] = useState<DRMOption>({ noCache: true } as DRMOption)

  const items = useSeletedStore((state) => state.selected);
  const selected = items && items.length > 0 ? items[0] : undefined;
  const openMorePDF = async () => {
    if (!selected) {
      return
    }
    const rt: any = await driveApi.regDoc(selected.path, selected.driveType, reqOption, false)

    window.open(rt?.result?.url, selected.name + JSON.stringify(reqOption));
  }
  const openMoreLite = async () => {
    if (!selected) {
      return
    }
    const rt: any = await driveApi.regDoc(selected.path, selected.driveType, true)
    window.open(rt?.result?.url, `${selected.name}-lite`);
  }
  const fetchDocInfo = useCallback(async (selected: DriveDto) => {
    try {
      if (selected.type !== 'file') {
        return;
      }

      const { result: { data } } = await driveApi.docInfo(selected.path, selected.driveType);
      setPage(data.page);
    } catch (error: any) {

    }
  }, []);

  useEffect(() => {
    if (open && selected) {
      fetchDocInfo(selected);
    }
  }, [open, selected, fetchDocInfo]);

  useEffect(() => {
    console.log(reqOption)
  }, [reqOption])
  return (
    <Box  >
      {(open && selected) && (
        <Paper
          elevation={1}
          sx={{
            width: `${SIDEBAR_WIDTH}px`
          }}>
          {selected.type === 'file' && (
            <DocumentPreview
              page={page}
              selected={selected}
            />
          )}
          <List>
            {selected.type === 'file' && (
              <>
                <Divider />
                <ListItemButton >
                  <div className='m-0 p-0'>
                    <div className='flex' onClick={openMorePDF}>
                      <ListItemIcon>
                        <OpenIcon />
                      </ListItemIcon>
                      <ListItemText>뷰어(More-PDF) 로 열기</ListItemText>
                    </div>
                    <div className='border p-2 rounded-sm'>
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          checked={reqOption.noexpire || false}
                          onChange={(e) => {
                            setReqOption({ ...reqOption, noexpire: e.target.checked })
                          }} />}
                        label="NO Expire"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          checked={reqOption.link || false}
                          onChange={(e) => {
                            setReqOption({ ...reqOption, link: e.target.checked })
                          }} />}
                        label="Link"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          checked={reqOption.debugText || false}
                          onChange={(e) => {
                            setReqOption({ ...reqOption, debugText: e.target.checked })
                          }} />}
                        label="DebugText"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          checked={reqOption.noCache || false}
                          onChange={(e) => {
                            setReqOption({ ...reqOption, noCache: e.target.checked })
                          }} />}
                        label="noCache"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          checked={reqOption.nosmooth || false}
                          onChange={(e) => {
                            setReqOption({ ...reqOption, nosmooth: e.target.checked })
                          }} />}
                        label="nosmooth"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          checked={reqOption.orgimage || false}
                          onChange={(e) => {
                            setReqOption({ ...reqOption, orgimage: e.target.checked })
                          }} />}
                        label="orgimage"
                      />
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          checked={reqOption.zoom || false}
                          onChange={(e) => {
                            setReqOption({ ...reqOption, zoom: e.target.checked })
                          }} />}
                        label="zoom"
                      />
                    </div>
                  </div>

                </ListItemButton>
                <ListItemButton onClick={openMoreLite}>
                  <ListItemIcon>
                    <OpenIcon />
                  </ListItemIcon>
                  <ListItemText>뷰어(Lite) 로 열기</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={props.openDefault}>
                  <ListItemIcon>
                    <OpenIcon />
                  </ListItemIcon>
                  <ListItemText>내장뷰어로 열기</ListItemText>
                </ListItemButton>
                <Divider />
              </>
            )}
            <ListItemButton
              onClick={onMove}>
              <ListItemIcon>
                <MoveIcon />
              </ListItemIcon>
              <ListItemText>이동</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={onRename}>
              <ListItemIcon>
                <RenameIcon />
              </ListItemIcon>
              <ListItemText>이름 변경</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={onTrash}>
              <ListItemIcon>
                <TrashIcon />
              </ListItemIcon>
              <ListItemText>휴지통</ListItemText>
            </ListItemButton>
          </List>
        </Paper>
      )}
    </Box>
  )
};
