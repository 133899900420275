import { Box, IconButton, Typography } from '@mui/material';
import {
  ArrowCircleLeft as LeftIcon,
  ArrowCircleRight as RightIcon
} from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { DriveDto } from '@dto/DriveDto';
import { formatDate } from '@util/format';
import { driveApi } from '@api';
import { encode } from 'base64-arraybuffer';

type DocumentPreviewProps = {
  page: number,
  selected?: DriveDto
};

export const DocumentPreview: React.FC<DocumentPreviewProps> = (props) => {
  const { page, selected } = props;
  const [nowPage, setNowPage] = useState<number>(0);
  const [src, setSrc] = useState<string>('');

  const fetchThumbnail = useCallback(
    async () => {
      try {
        if (!selected) {
          throw new Error('');
        }

        const result = await driveApi.thumbnail(`${selected.path}`, nowPage, selected.driveType);
        const canvas = encode(result);

        setSrc(`data:image/png;base64,${canvas}`);
      } catch (error) {
        setSrc('');
      }
    },
    [selected, nowPage]
  );

  useEffect(() => {
    if (selected) {
      setNowPage(0);
    }
  }, [selected]);


  useEffect(() => {
    fetchThumbnail();
  },
    [fetchThumbnail])

  const handlePrev = () => {
    if (nowPage > 0) {
      setNowPage((nowPage) => nowPage - 1);
    }
  };

  const handleNext = () => {
    if (nowPage < page) {
      setNowPage((nowPage) => nowPage + 1);
    }
  };

  return (
    <Box>
      {selected && (
        <>
          <Box
            sx={{
              py: 2,
              px: 2,
            }}>
            <Box
              sx={{
                backgroundColor: '#EFEFEF',
                borderRadius: 2,
                pt: 2,
              }}>
              <Box
                sx={{
                  width: 98,
                  height: 133,
                  backgroundColor: '#FFF',
                  mx: 'auto'
                }}
              >
                <img
                  src={src}
                  alt={`thumbnail-page-${page}`}
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  width: 120,
                  mx: 'auto',
                  mb: 2,
                }}>
                <IconButton
                  onClick={handlePrev}
                  disabled={nowPage < 1}
                >
                  <LeftIcon />
                </IconButton>
                <IconButton
                  onClick={handleNext}
                  disabled={nowPage === page - 1}>
                  <RightIcon />
                </IconButton>
              </Box>
            </Box>
            <Typography noWrap>{selected.name}</Typography>
            <Typography>{formatDate(selected.uDate)}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
}