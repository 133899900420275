import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { BoxProps, SxProps } from '@mui/material';
import { Collapse, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

type Menu = {
  path: string,
  label: string,
  children?: Menu[]
};

const MainMenus: Menu[] = [
  {
    path: '/doc',
    label: '문서',
    children: [
      {
        path: '/doc',
        label: '공유문서'
      },
      // {
      //   path: '/my',
      //   label: '내 문서'
      // },
      // {
      //   path: '/trash',
      //   label: '휴지통'
      // },
      {
        path: '/cert',
        label: '이용 인증 관리'
      },
      {
        path: '/annot',
        label: '주석 관리'
      },
    ]
  },
  {
    path: '/org',
    label: '조직'
  },
  {
    path: '/actionLog',
    label: '로그',
    children: [
      {
        path: '/actionLog',
        label: '문서 로그',
      },
      {
        path: '/systemLog',
        label: '시스템 로그'
      }
    ]
  },
  {
    path: '/liveUpdate',
    label: '프린터',
    children: [
      {
        path: '/liveUpdate',
        label: '앱 관리'
      },
      {
        path: '/printerInfo',
        label: '사용 불가 프린터 관리'
      }
    ]
  },
  {
    path: '/admin',
    label: '관리자'
  },
  {
    path: '/cut',
    label: '컷 '
  }
];

type MenuItemProps = {
  menu: Menu,
  onClickMenu: (path: string) => void;
  sx?: SxProps,
  depth?: number,
};

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const {
    menu,
    onClickMenu,
    sx = {},
    depth = 1,
  } = props;
  const { pathname } = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    function getPaths (menus: Menu[]): string[] {
      const paths: string[] = [];

      menus.forEach((m: Menu) => {
        paths.push(m.path);
        if (m.children) {
          const ps: string[] = getPaths(m.children);
          paths.push(...ps);
        }
      });

      return paths;
    }

    const { path, children } = menu;
    const paths: string[] = [path];

    if (children) {
      const ps: string[] = getPaths(children);
      paths.push(...ps);
    }

    for (let i = 0; i < paths.length; i++) {
      if (pathname.startsWith(paths[i])) {
        setOpen(true)
        break;
      } else {
        setOpen(false)
      }
    }
  }, [menu, pathname]);

  const handleClickItem = () => {
    onClickMenu(menu.path);
  };

  if (menu.children) {
    return (
      <>
        <ListItem>
          <ListItemButton
            onClick={handleClickItem}
            sx={{ pl: depth === 1 ? 'inherit' : 4 * (depth - 1) }}
          >
            <ListItemText>{menu.label}</ListItemText>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
        </ListItem>
        <Collapse in={open} unmountOnExit>
          <List dense>
            {menu.children.map((m: Menu, index: number) => (
              <MenuItem
                key={`menu-${index}-${m.path}`}
                menu={m}
                onClickMenu={onClickMenu}
                sx={{ pl: 4 * depth }}
                depth={depth + 1}
              />
            ))}
          </List>
        </Collapse>
      </>
    )
  } else {
    return (
      <ListItem>
        <ListItemButton
          selected={pathname.startsWith(menu.path)}
          onClick={handleClickItem}
          sx={sx}
        >
          <ListItemText>{menu.label}</ListItemText>
        </ListItemButton>
      </ListItem>
    )
  }
};


export interface MainMenuProps extends BoxProps { }

export const MainMenu: React.FC<MainMenuProps> = ({ ...props }) => {
  const navigate = useNavigate();

  const handleClickItem = (value: any) => {
    navigate(value);
  };

  return (
    <List dense>
      {MainMenus.map((m: Menu, index: number) => (
        <MenuItem
          key={`menu-${index}`}
          menu={m}
          onClickMenu={handleClickItem}
        />
      ))}
    </List>
  )
};