import { UcodAdmin } from '@type';

const Annot: UcodAdmin = {
  id: 'annot',
  label: '문서 주석 관리',
  fields: [
    {
      id: 'docHash',
      label: '문서 Hash',
      type: 'string',
      pk: true
    },
    {
      id: 'userId',
      label: '사용자 ID',
      type: 'string',
      pk: true
    },
    {
      id: 'status',
      label: '상태',
      type: 'string'
    },
    {
      id: 'annot',
      label: '주석',
      type: 'json'
    },
    {
      id: 'cdate',
      label: '생성일',
      type: 'date'
    },
    {
      id: 'udate',
      label: '최종 수정일',
      type: 'date'
    }
  ],
  list: ['docHash', 'userId', 'udate'],
  show: ['docHash', 'userId', 'status', 'annot', 'udate'],
  disableCreate: true,
  disableEdit: true
}

export default Annot;