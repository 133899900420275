import React from 'react';
import { useConfirm, useProgress, useToast } from '@hook';
import { NotInterested as BanIcon } from '@mui/icons-material';
import api from '@api';
import { Button, ButtonProps } from '@mui/material';

type OrgForceCloseProps = ButtonProps & {
  selected: readonly string[],
  onCompleted: () => void;
};

/**
 * 강제 종료 건
 * @param props
 * @returns
 */
export const OrgForceClose: React.FC<OrgForceCloseProps> = (
  props
) => {
  const { selected, onCompleted, ...other } = props;
  const { confirmOpen } = useConfirm();
  const { progressOpen, progressClose } = useProgress();
  const { toastOpen } = useToast();

  const handleClick = () => {
    confirmOpen({
      title: '확인',
      content: '선택된 항목의 사용을 종료시키겠습니까?',
      okTitle: '종료',
      onOk: handleOk
    })
  };

  const handleOk = async () => {
    try {
      progressOpen('사용 종료', '사용을 종료하고 있습니다.');
      const { result } = await api['orgApi'].updateUse(selected);
      onCompleted();
      toastOpen(`${result.count}건의 항목의 사용을 종료하였습니다.`);
    } catch (error: any) {
    } finally {
      progressClose();
    }
  };

  return (
    <Button
      color="error"
      variant="contained"
      disableElevation
      {...other}
      onClick={handleClick}
      startIcon={<BanIcon />}
      disabled={selected.length === 0}
      >
      사용 종료
    </Button>
  )
};