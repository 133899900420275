/**
 * 조직종류
 */
export enum ORG {
  OPERATOR = '00', // 운영사
  MEMBER = '10', // 회원사
  CLASS = '30', // 반
}

/**
 * 조직상태
 */
export enum ORG_STATE {
  NONE = '00', // NONE
  READY = '20', // 준비중
  USED = '30', // 사용
  STOP = '40', // 일시중지
  REMOVE = '90', // 삭제
}

/**
 * 커리큘럼 타입
 */
export enum COURSE_TYPE {
  MANAGER = '00', // 운영사 / 전시용 커리큘럼
  COMPANY = '10', // 회원사 커리큘럼
  SUBJECT = '20', // 수업 커리큘럼
}

export enum SUBJECT_TYPE {
  MANAGER = '00', // 운영사 / 전시용 커리큘럼
  COMPANY = '10', // 회원사 커리큘럼
  SUBJECT = '20', // 수업 커리큘럼
}

export enum BOARD_TYPE {
  NORMAL = '00', // 일반
  AGREEMENT = '10', // 약관동의
  NOTICE = '20', // 공지사항
  SURVEY = '30', // 투표
  QUIZ = '40', // 퀴즈
}

export enum FILE_TYPE {
  NORMAL = '00', // 사용자/일반
  PLAN = '10', // 교안
  TEXTBOOK = '11', // 교재
  PROFILE = '40', // 프로파일
  FOLDER = '50', // 폴더
}

// 반복주기
export enum DURATION_UNIT {
  HOUR = 'h', // 시간
  DAY = 'd', // 일
  WEEK = 'w', // 주
  MONTH = 'm', // 월
}

/**
 * 회원상태
 */
export enum USER_STATE {
  NONE = '00',
  WAIT = '10', // 대기중 (이메일 발송)
  CONFIRM = '20', // 사용자 확인
  ACTIVATE = '30', // 회원사에서 확인 완료
  STOP = '40', // 이용중지
  REMOVE = '90', // 회원사에서 삭제
}

export enum POST_STATE {
  BLIND = '00', // 비공개 / 작성중
  GROUP_OPEN = '20', // 그룹 공개 (로그인시 조회)
  OPEN = '30', // 전체 공개
  CLOSE = '40', // 닫음 (비공개)
  BLOCK = '80', // 블럭 (관리자 닫음)
  REMOVE = '90', // 사용자 삭제
  REMOVE_ADMIN = '91', // 관리자 삭제
}

/**
 * 역할
 */
export enum ROLE {
  SYSTEM = '10', // 시스템 관리자
  // MANAGER = 11, // 운영사 - 운영자
  LOG = '20', // 로그 관리자
  // DEPART = 30, // 회원사 - 운영자 (선생님)
  USER = '50', // 이용자
}

export enum USER_EXIST {
  NOT_FOUND = 10, // 사용자 존재하지 않음
  NOT_EQUAL_ORG = 20, // 같은 ORG에 존재하지 않는 사용자
  EQUAL_ORG = 90 // 같은 ORG에 존재하는 사용자
};