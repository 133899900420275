import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';

export interface ToastState {
  isOpen: boolean;
  message?: string | null;
}

const initialState: ToastState = {
  isOpen: false,
  message: null,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ToastState>) => {
      const { isOpen, message } = action.payload;

      state.isOpen = isOpen;
      state.message = message;
    },

    close: (state) => {
      state.isOpen = false;
      state.message = null;
    }
  }
});

export const selectToast = (state: RootState) => state.toast;

export const { open, close } = toastSlice.actions;
export default toastSlice.reducer;
