import instance from './instance';
import ResultDto from '@dto/ResultDto';
import ListResultDto from '@dto/ListResultDto';
import { PrinterInfoDto } from '@dto/PrinterInfoDto';
import DetailResultDto from '@dto/DetailResultDto';

/**
 * 사용 불가능 프린트 목록
 * @param page
 * @returns
 */
export const find = async (
  page: number,
  { useYn, targetType }: {
    useYn?: readonly string[],
    targetType?: readonly string[]
  }
)
  : Promise<ResultDto<ListResultDto<PrinterInfoDto>>> =>
    await instance.get('/printer/delist', {
      params: {
        page,
        useYn,
        targetType
      }
    });

export const findByOne = async ({
  targetName,
  targetType
}: { targetName: string, targetType: string}): Promise<ResultDto<DetailResultDto<PrinterInfoDto>>> =>
  await instance.get(`/printer/delist/${targetName}/${targetType}`);


export const create = async (params: PrinterInfoDto):
  Promise<ResultDto<any>> =>
  await instance.post(`/printer/delist`, params);

export const edit = async (params: PrinterInfoDto):
  Promise<ResultDto<any>> =>
  await instance.patch(`/printer/delist/${params.targetName}/${params.targetType}`, {
    useYn: params.useYn,
    description: params.description
  });
