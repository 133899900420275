import ResultDto from '@dto/ResultDto';
import instance from './instance';
import ListResultDto from '@dto/ListResultDto';
import { ActionLogDto } from '@dto/ActionLogDto';

export const findByActionLog = async (
  page: number,
  status?: readonly string[],
  actionType?: readonly string[]
  ): Promise<ResultDto<ListResultDto<ActionLogDto>>> =>
  await instance.get('/actionLog', {
    params: {
      page,
      status,
      actionType
    }
  });