import ResultDto from '@dto/ResultDto';
import instance from './instance';
import ListResultDto from '@dto/ListResultDto';
import { ActionLogDto } from '@dto/ActionLogDto';
import DetailResultDto from '@dto/DetailResultDto';

export const find = async (
  page: number,
  {
    status,
    actionType
  }: {
    status?: readonly string[],
    actionType?: readonly string[]
  }
  ): Promise<ResultDto<ListResultDto<ActionLogDto>>> =>
  await instance.get('/actionLog', {
    params: {
      page,
      status,
      actionType
    }
  });

export const findByOne = async (
  { uid }: { uid: string }
): Promise<ResultDto<DetailResultDto<ActionLogDto>>> => await instance.get(`/actionLog/${uid}`);