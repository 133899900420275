import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import useLoading from './useLoading';
import { DriveDto } from '@dto/DriveDto';
import { driveApi } from '@api';

function useFetchDrive () {

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [driveList, setDriveList] = useState<DriveDto[]>([]);
  const { loadingOpen, loadingClose } = useLoading();
  const [nowPath, setNowPath] = useState<string>();

  const fetchData = useCallback(
    async () => {
      try {
        loadingOpen();

        let path: string = '';
        let type: 'doc' | 'trash' | 'my' = 'my';
        let isRoot: boolean = true;

        if (pathname === '/doc') {
          type = 'doc';
        } else if (pathname === '/my') {
          type = 'my';
        } else {
          type = 'trash';
        }

        if (search) {
          const query = qs.parse(search, { ignoreQueryPrefix: true });
          path += `${query.q}`;
          if (query.q) {
            isRoot = false;
          }
        }

        const { result } = await driveApi.find(path, type);
        setNowPath(path);
        let children = result.children || [];

        if (!isRoot) {
          let path: string = '';
          const query = qs.parse(search, { ignoreQueryPrefix: true });
          console.log("Riqe", query.q);

          if (query.q) {
            const qq = (query.q as string).split('/')
            if (qq.length > 1) {
              path = qq.slice(0, qq.length - 1).join('/');
            }
          }

          const upFolder: DriveDto = {
            path,
            hash: '',
            driveType: type,
            name: '',
            size: '',
            sizeInBytes: 0,
            type: 'none',
            uDate: new Date()
          };

          children = [upFolder, ...children];
        }
        setDriveList(children);
      } catch (error: any) {

      } finally {
        loadingClose();
      }
    },
    // eslint-disable-next-line
    [pathname, search] // 패스 명이 바뀌거나,검색어가 바뀌면...
  );

  useEffect(() => {
    fetchData();
    return () => {
      console.log("Fetch Out.....")
    }
  }, [fetchData]);


  const folderMove = (path: string) => {
    navigate(`${pathname}?q=${path}`);
  }

  const nowFolderName = useMemo(
    () => {
      let folderName: string = '';

      if (pathname === '/doc') {
        folderName = '내 문서'
      } else {
        folderName = '휴지통'
      }

      if (search) {
        const query = qs.parse(search, { ignoreQueryPrefix: true });
        if (query.q) {
          folderName = (query.q as string).split('/').slice(-1)[0];
        }
      }

      return folderName;
    },
    [pathname, search]
  );

  const reloadData = () => fetchData();

  return {
    driveList,
    folderMove,
    nowFolderName,
    nowPath,
    reloadData
  };
};

export default useFetchDrive;
