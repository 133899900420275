import { Typography } from '@mui/material';
import { UcodField } from '@type'
import React from 'react';

type FormLabelProps = {
  field: UcodField,
  errors?: boolean
};

export const FormLabel: React.FC<FormLabelProps> = (props) => {
  const { field: f, errors = false } = props;

  console.log("Form", props)
  return (
    <Typography
      component={'label'}
      mb={1}
      variant="body2"
      fontWeight={500}
      color={errors ? 'error' : 'inherit'}
    >
      {f.label ? f.label : f.id.toUpperCase()}
      <span style={{ marginLeft: 4 }}>
        {(f.required || f.pk) ? '*' : ''}
      </span>
    </Typography>
  )
}