import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from './useUser';

/**
 * Permission 체크 Hook
 */
function useCheckPermission () {
  const { pathname } = useLocation();
  const { user } = useUser();

  useEffect(() => { }, [pathname, user]);
};

export default useCheckPermission;