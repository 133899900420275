import { UserDto } from '@dto/UserDto';
import instance from './instance';

/**
 * 로그인
 */
export const login = async (email: string, password: string)
  : Promise<{ status: number, result: any }> =>
  await instance.post('/auth/email/login', {
    email,
    password,
    org: {
      id: 'ucod'
    }
  });

/**
 * 로그아웃
 * @returns
 */
export const logout = async (): Promise<any> =>
  await instance.post('/auth/email/logout');


export const me = async (): Promise<any> => await instance.get('/auth/me');

/**
 * 비밀번호 변경
 * @param uuid
 * @param newPassword
 * @param oldPassword
 * @returns
 */
export const changePassword = async (
  uuid: string,
  newPassword: string,
  oldPassword: string
) => await instance.post('/auth/changepasswd', {
  uuid,
  newpasswd: newPassword,
  oldpasswd: oldPassword
});

/**
 * 정보 업데이트
 * @param user
 * @returns
 */
export const update = async (
  user: UserDto
) => await instance.patch('/me', user);