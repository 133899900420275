import { useAppDispatch, useAppSelector } from '@store/hook';
import React from 'react';
import { open, ok, selectAlert } from '@store/alert';

/**
 * Toast 상태 처리
 * @returns
 */
function useAlert () {
  const dispatch = useAppDispatch();
  const alert = useAppSelector(selectAlert);

  const alertOpen = (payload: {
    title: string,
    content: React.ReactNode,
    onOk?: () => void,
    okTitle?: string,
  }) => {
    dispatch(
      open({
        isOpen: true,
        ...payload
      })
    );
  };

  const alertOk = () => {
    dispatch(ok());
  }

  return {
    alert,
    alertOpen,
    alertOk
  };
};

export default useAlert;
