import { USE_YN } from '@const';
import { UcodAdmin } from '@type';

const Cert: UcodAdmin = {
  id: 'cert',
  label: '문서 이용 인증 관리',
  fields: [
    {
      id: 'cn',
      label: 'CN',
      type: 'string',
    },
    {
      id: 'certId',
      label: '인증 아이디',
      pk: true,
      type: 'string',
    },
    {
      id: 'title',
      label: '문서 제목',
      type: 'string',
    },
    {
      id: 'docType',
      label: '문서 종류',
      type: 'string',
      format(value?: string) {
        if (value) {
          return value.toUpperCase();
        } else {
          return '';
        }
      },
    },
    {
      id: 'docPath',
      label: '문서 경로',
      type: 'string',
    },
    {
      id: 'docHash',
      label: '문서 HASH',
      type: 'string',
    },
    {
      id: 'orgId',
      label: '조직',
      type: 'string',
    },
    {
      id: 'userId',
      label: '사용자',
      type: 'string',
    },
    {
      id: 'extra',
      type: 'json',
    },
    {
      id: 'expired',
      label: '세션 종료 여부',
      type: 'string',
    },
    {
      id: 'cdate',
      label: '생성일',
      type: 'date',
    },
    {
      id: 'udate',
      label: '최종 수정일',
      type: 'date',
    },
  ],
  list: ['cn', 'certId', 'title', 'docType', 'orgId', 'userId', 'expired', 'udate'],
  show: [
    'cn',
    'certId',
    'title',
    'docPath',
    'docHash',
    'docType',
    'orgId',
    'userId',
    'expired',
    'extra',
    'udate',
  ],
  disableCreate: true,
  disableEdit: true,
  filters: [
    {
      id: 'expired',
      label: '완료여부',
      reference: USE_YN,
    },
    {
      id: 'org',
      label: '조직',
      reference: 'org',
    },
  ],
  useListCheckbox: true,
  listCheckboxComponentName: 'certForceClose',
};

export default Cert;