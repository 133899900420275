import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';


export interface ConfirmState {
  isOpen: boolean;
  title: string;
  content: React.ReactNode;
  okTitle?: string;
  cancelTitle?: string;
  onOk?: () => void;
  onCancel?: () => void;
};

const initialState: ConfirmState = {
  isOpen: false,
  title: '확인',
  content: null,
  okTitle: '확인',
  cancelTitle: '취소'
};

export const confirmSlice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ConfirmState>) => {
      const newState = Object.assign({}, initialState, { ...action.payload })
      return newState;
    },

    close: (state) => {
      return initialState;
    },

    ok: (state) => {
      return initialState;
    },
  },
});

export const selectConfirm = (state: RootState) => state.confirm;
export const { open, close, ok } = confirmSlice.actions;
export default confirmSlice.reducer;