import React from 'react';
import type { AppBarProps, SxProps, Theme } from '@mui/material';
import { Toolbar, Typography, AppBar, Switch, FormControlLabel, Box } from '@mui/material';
import { SIDEBAR_WIDTH } from '@const';
import { ProfileMenu } from './ProfileMenu';

const appBarSx: SxProps<Theme> = {
  zIndex: (theme) => theme.zIndex.drawer + 1,
  boxShadow: 'none',
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`
};

const logoSx: SxProps<Theme> = {
  width: `${SIDEBAR_WIDTH - 24}px`,
  maxWidth: `${SIDEBAR_WIDTH - 24}px`
}

interface HeaderProps extends AppBarProps { }

/**
 * AppBar..
 * @param
 * @returns
 */
export const Header: React.FC<HeaderProps> = ({ ...props }) => {
  return (
    <AppBar
      position="fixed"
      sx={appBarSx}
      {...props}
    >
      <Toolbar>
        <Typography sx={logoSx}>
          More DMS
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <FormControlLabel control={<Switch color="secondary" />} label="Test" />
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  )
}