import React from 'react';
import { Alert, Box, Container, Typography } from '@mui/material';

interface AuthWrapperProps {
  pageName: string;
  errorMessage?: string | null;
  children?: React.ReactNode | null;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({
  errorMessage = null,
  ...props
}) => {
  return (
    <Container maxWidth={'xs'}>
      <Box
        sx={{
          p: 2,
          marginTop: 16
        }}>
        <Typography
          component="h1"
          variant="h5"
          fontWeight={700}>
            MoreDMS 관리자
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          fontWeight={700}
          sx={{ marginBottom: 4 }}>
            {props.pageName}
        </Typography>
        {errorMessage && (
          <Alert
            severity="error"
            sx={{ marginBottom: 2 }}>
              {errorMessage}
          </Alert>
        )}
        {props.children}
      </Box>
    </Container>
  )
}