import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { flatten } from 'flat';
import {
  Box,
  Button,
  Container,
  FormControl,
  Typography
} from '@mui/material';
import {
  List as ListIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import Type, { UcodField } from '@type';
import api from '@api';
import { useLoading } from '@hook';
import ReactJson from '@microlink/react-json-view';
import theme from '@style/theme';
import { ShowField } from '@components/Common/Form/ShowField';
import { BreadCrumb } from '@components/Common/Breadcrumb';



export const CommonShow = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [fields, setFields] = useState<UcodField[]>([]);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [item, setItem] = useState<any>({});
  const { loadingOpen, loadingClose } = useLoading();

  const fetchData = useCallback(
    async () => {
      try {
        loadingOpen();

        if (!params.id) {
          return;
        }

        const { fields } = Type[params.id];

        const pkIds = fields.filter((v) => v.pk).map((v) => v.id);
        let p: { [key: string]: string } = {};

        pkIds.forEach((pkId: string, index: number) => {
          let key = `pk${index + 1}`;
          p[pkId as string] = params[key] as string;
        });

        console.log('PKS >> ', pkIds);

        const key = params.id + 'Api';
        const { result } = await api[key].findByOne(p);
        setItem(flatten(result.data));
      } catch (error) {
        // 목록으로 전환
      } finally {
        loadingClose();
      }
    },
    // eslint-disable-next-line
    [params]
  );

  useEffect(
    () => {
      if (params.id) {
        const { fields, show, disableShow, disableEdit = false } = Type[params.id];

        if (disableShow) {
          // 접근 불가
          navigate(`/${params.id}`);
        }

        setDisableEdit(disableEdit);

        if (show) {
          setFields(fields.filter((f) => show.includes(f.id)));
        } else {
          setFields(fields);
        }
      }
    },
    // eslint-disable-next-line
    [params]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickList = () => {
    navigate(`/${params.id}`);
  };

  const handleClickEdit = () => {
    let to: string = `/${params.id}/edit/${params.pk1}`;

    if (params.pk2) {
      to += `/${params.pk2}`;
    }

    if (params.pk3) {
      to += `/${params.pk3}`;
    }

    navigate(to)
  };

  return (
    <React.Fragment>
      <BreadCrumb />
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            mb: 2
          }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClickList}
              startIcon={<ListIcon />}
              >
                목록으로
            </Button>
            {!disableEdit && (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                onClick={handleClickEdit}
                startIcon={<EditIcon />}
                sx={{ mr: 1 }}
                >
                  수정
              </Button>
            )}
        </Box>
        {item && (
          fields.map((f: any, index: number) => (
            <FormControl
              key={`form-${index}`}
              fullWidth
              sx={{ mb: 2 }}
              >
              <Typography
                component={'label'}
                mb={1}
                variant="body2"
                fontWeight={500}
                >
                {f.label ? f.label : f.id.toUpperCase()}
              </Typography>
              {/* TYPE에 따라 보여지는 것이 달라지게 해야 한다. */}
              {f.type !== 'json' && (
                <ShowField
                  multiline={f.type === 'textarea'}>
                  {item[f.id]}
                </ShowField>
              )}
              {f.type === 'json' && (
                <ReactJson
                  src={JSON.parse(item[f.id] || '{}')}
                  style={{
                    border: `1px solid #BDBDBD`,
                    borderRadius: '4px',
                    padding: '10px 14px',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.875rem',
                    overflowY: 'auto'
                  }}
                  />
              )}
            </FormControl>
          ))
        )}
        <Box
          sx={{
            mt: 4,
            mb: 6
          }}>
        </Box>
      </Container>
    </React.Fragment>
  )
};