import { UserDto } from '@dto/UserDto';
import { useAppDispatch, useAppSelector } from '@store/hook';
import { selectUser, removeUser, setUser } from '@store/user';

/**
 * 로그인 한 사용자 정보 가져오기
 * @returns
 */
function useUser () {
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const dispatchUser = (u: any) => {
    dispatch(setUser(u as UserDto));
  };

  const clearUser = () => {
    dispatch(removeUser());
  };

  return {
    user,
    dispatchUser,
    clearUser,
  }
};

export default useUser;
