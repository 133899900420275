import {
  configureStore,
  ThunkAction,
  Action
} from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import { all } from '@redux-saga/core/effects';

import progressReducer from './progress';
import toastReducer from './toast';
import confirmReducer from './confirm';
import watchOpen from './confirm.saga';
import userReducer from  './user';
import loadingReducer from './loading';
import alertReducer from './alert';
import alertWatchOpen from './alert.saga';

const sagaMiddleware = createSagaMiddleware();
function* rootSaga() {
  yield all([watchOpen(), alertWatchOpen()]);
}

export const store = configureStore({
  reducer: {
    progress: progressReducer,
    toast: toastReducer,
    confirm: confirmReducer,
    user: userReducer,
    loading: loadingReducer,
    alert: alertReducer
  },
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;