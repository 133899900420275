
/**
 * Production모드일 때 로그를 막는다
 */
export const ProductionBlockLog = () => {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
}