import React, { useCallback, useEffect, useState } from 'react';
import { FormHelperText, MenuItem, Select as MuiSelect } from '@mui/material';
import type { UseFormRegisterReturn } from 'react-hook-form';
import type { SelectProps as MuiSelectProps } from '@mui/material';
import Type from '@type';
import type { UcodField } from '@type';
import { useParams } from 'react-router-dom';
import api from '@api';

type SelectProps = MuiSelectProps & {
  field: UcodField,
  register?: UseFormRegisterReturn<any>
  helperText?: React.ReactNode;
};

export const Select: React.FC<SelectProps> = (props) => {
  const params = useParams();
  const {
    field: f,
    register,
    helperText,
    // value: _value,
    ...other
  } = props;
  const [options, setOptions] = useState<{ label: string, value: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const label: string = f.label ? f.label : f.id.toUpperCase();
  const apiKey: string = `${params.id}Api`;
  // const [value, setValue] = useState<any>('');

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await api[apiKey].findAll();
      console.log(result);
    } catch (error: any) {

    } finally {
      setLoading(false);
    }
  },
  // eslint-disable-next-line
  []);


  useEffect(() => {
    if (params.id) {
      const { reference } = Type[params.id];
      console.log(reference);

      if (reference) {
        const result = reference.find((r) => r.id === f.id);

        if (Array.isArray(result?.reference)) {
          setOptions(result?.reference || []);
        } else {
          fetchData();
        }
      }
    }
  }, [params, f.id, fetchData]);

  // useEffect(() => {
  //   console.log('VALUE >> ', _value);
  //   setValue(_value || '');
  // }, [_value]);

  return (
    <React.Fragment>
      <MuiSelect
        variant="outlined"
        color="secondary"
        size="small"
        fullWidth
        disabled={loading}
        inputProps={{
          placeholder: `${label}을(를) 선택해주세요.`
        }}
        // value={value}
        {...register}
        {...other}>
          <MenuItem
            value=""
            sx={{ fontSize: '0.875rem' }}
            >
            {`${label}을(를) 선택해주세요.`}
          </MenuItem>
          {options.map((option, index: number) => (
            <MenuItem
              value={option.value}
              key={`${params.id}-${index}`}
              sx={{ fontSize: '0.875rem' }}
              >
                {option.label}
            </MenuItem>
          ))}
      </MuiSelect>
      {helperText && (
        <FormHelperText
          error={other.error}>
            {helperText}
        </FormHelperText>
      )}
    </React.Fragment>
  )
};