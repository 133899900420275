import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import { Layout } from '@page/Layout';
// import { CommonList } from '@page/Common/CommonList';
import { CommonShow } from '@page/Common/CommonShow';
import { CommonCreate } from '@page/Common/CommonCreate';
import { CommonEdit } from '@page/Common/CommonEdit';
import { SystemLog } from '@page/SystemLog';
import { Login } from '@page/Auth/Login';
import { FindPassword } from '@page/Auth/FindPassword';
import { DocList } from '@page/Doc/DocList';
import { TrashList } from '@page/Doc/TrashList';
import { Profile } from '@page/Profile';
import CommonList from '../page/Common/CommonList';

const components = {

  'login': <Login />,
  'findpassword': <FindPassword />,
  'show': <CommonShow />
}
/**
 * Router 설정
 */
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'login',
        element: components['login']
      },
      {
        path: 'findpassword',
        element: <FindPassword />
      },
      {
        path: '',
        element: <Layout />,
        children: [
          {
            path: 'doc',
            element: <DocList type="doc" />
          },
          // {
          //   path: 'my',
          //   element: <DocList type="my" />
          // },
          {
            path: 'trash',
            element: <TrashList />
          },
          {
            path: ':id',
            element: <CommonList />
          },
          {
            path: ':id/create',
            element: <CommonCreate />
          },
          {
            path: ':id/show/:pk1/:pk2?/:pk3?',
            element: <CommonShow />
          },
          {
            path: ':id/edit/:pk1/:pk2?/:pk3?',
            element: <CommonEdit />
          },
          {
            path: 'systemLog',
            element: <SystemLog />
          },
          {
            path: 'profile',
            element: <Profile />
          }
        ]
      }
    ]
  },
]);

export default router;
