import { useAppDispatch, useAppSelector } from '@store/hook';
import { open, close, selectLoading } from '@store/loading';

/**
 * Progress 상태 처리
 * @returns
 */
function useLoading () {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);

  const loadingOpen = () => {
    dispatch(
      open()
    );
  };

  const loadingClose = () => {
    dispatch(close())
  };

  return {
    loading,
    loadingOpen,
    loadingClose
  };
};

export default useLoading;
