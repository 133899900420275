import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';


export interface AlertState {
  isOpen: boolean;
  title?: string;
  content: React.ReactNode;
  okTitle?: string;
  onOk?: () => void;
};

const initialState: AlertState = {
  isOpen: false,
  title: '확인',
  content: null,
  okTitle: '확인',
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<AlertState>) => {
      const newState = Object.assign({}, initialState, { ...action.payload })
      return newState;
    },

    close: (state) => {
      return initialState;
    },

    ok: (state) => {
      return initialState;
    },
  },
});

export const selectAlert = (state: RootState) => state.alert;
export const { open, close, ok } = alertSlice.actions;
export default alertSlice.reducer;