import React, { useState } from 'react';
import { SIDEBAR_WIDTH } from '@const';
import { Box, Button, ButtonProps, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import {
  FilterList as FilterIcon,
  KeyboardArrowLeft as ArrowLeftIcon
} from '@mui/icons-material';
import { UcodFilter } from '@type';
import { FilterForm } from './FilterForm';

type FilterSidebarProps = {
  filters: UcodFilter[],
  buttonProps?: ButtonProps,
  onFiltering: (filters: any) => void,
};

export const FilterSidebar: React.FC<FilterSidebarProps> = (props) => {
  const {
    filters: _filters,
    buttonProps,
    onFiltering
  } = props;

  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});

  const handleToggleShow = () => {
    setShowSidebar((showSidebar) => !showSidebar);
  };

  const handleSelected = (id: string, selected: any) => {
    const f = {
      [id]: selected
    };
    const newFilters: any = Object.assign({}, filters, f);
    setFilters(newFilters);
  };

  const handleSearch = () => {
    onFiltering(filters);
    handleToggleShow();
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleToggleShow}
        startIcon={<FilterIcon />}
        {...buttonProps}
        >
          필터
      </Button>
      {showSidebar && (
        <Drawer
          open={showSidebar}
          variant="temporary"
          anchor="right">
          <Toolbar />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: SIDEBAR_WIDTH,
              px: 2,
              py: 2,
              height: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2
              }}>
              <IconButton
                onClick={handleToggleShow}
                sx={{ mr: 1 }}>
                <ArrowLeftIcon />
              </IconButton>
              <Typography
                component={'h1'}
                variant="h6"
                >
                  필터
              </Typography>
            </Box>
            <Box>
              {_filters.map((f: UcodFilter) => (
                <FilterForm
                  key={`filter-${f.id}`}
                  label={f.label || f.id.toUpperCase()}
                  items={f.reference as any}
                  onSelected={(selected) => handleSelected(f.id, selected)}
                  />
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                mt: 'auto'
              }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  sx={{ width: '100%' }}
                  onClick={handleSearch}
                  >
                    검색
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  sx={{ width: '100%' }}
                  onClick={handleToggleShow}
                  >
                    취소
                </Button>
            </Box>
          </Box>
        </Drawer>
      )}
    </React.Fragment>
  )
};