import format from 'date-fns/format';

const DEFAULT_DATE_FORMAT: string = 'yyyy-MM-dd hh:mm:ss';

/**
 * 용량에 대한 포맷
 * 
 * @param bytes 
 * @param decimals 
 * @returns 
 */
export const formatBytes = (bytes: number, decimals: number) => {
  if (bytes === 0) return `0 Bytes`;

  const k: number = 1024;
  const dm: number = decimals || 2;
  const sizes: readonly string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i: number = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat(`${(bytes / Number(Math.pow(k, i).toFixed(dm)))} ${sizes[i]}`);
}

/**
 * 날짜에 관한 포맷
 * @param date
 * @param format
 */
export const formatDate = (date: Date | string, f: string = DEFAULT_DATE_FORMAT) => {
  if (date instanceof Date) {
    return format(date, f);
  }

  const d = Number(new Date(date));

  if (d === 0 || isNaN(d)) {
    return '';
  }

  return format(new Date(date), f);
}