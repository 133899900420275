import * as authApi from './auth';
import * as adminApi from './admin';
import * as orgApi from './org';
import * as logApi from './log';
import * as certApi from './cert';
import * as annotApi from './annot';
import * as driveApi from './drive';
import * as printerInfoApi from './printerInfo';
import * as liveUpdateApi from './liveUpdate';
import * as actionLogApi from './actionLog';

const apis: {
  [key: string]: any
} = {
  authApi,
  adminApi,
  orgApi,
  logApi,
  certApi,
  annotApi,
  driveApi,
  printerInfoApi,
  liveUpdateApi,
  actionLogApi
};

export {
  authApi,
  adminApi,
  orgApi,
  logApi,
  certApi,
  annotApi,
  driveApi,
  printerInfoApi,
  liveUpdateApi,
};

export default apis;