import { useEffect, useState } from 'react';
import { DOC_MOVE_ID, DOC_NAME_CHANGE_ID, DOC_NEW_FOLDER_ID, DOC_TRASH_ID, DOC_UPLOAD_ID } from '@const';
import { useFetchDrive, useToast } from '@hook';
import { Box, } from '@mui/material';
import { DriveDto } from '@dto/DriveDto';
import { FolderList } from '@components/Folder/FolderList';
import { NewFolderModal } from '@components/Folder/NewFolderModal';
import { FolderToolbar } from '@components/Folder/FolderToolbar';
import { FileMoveModal } from '@components/Folder/FileMoveModal';
import { FileRenameModal } from '@components/Folder/FileRenameModal';
import { TrashMoveConfirm } from '@components/Folder/TrashMoveConfirm';
import { DocumentUploadModal } from '@components/Folder/DocumentUploadModal';
import { FolderSidebar } from '@components/Folder/FolderSidebar';

import useSeletedStore from '../../store/mystore';

type DocListProp = {
  type: "doc" | "my"
}

/**
 * TODO: 하단 마진 확인할것.
 * @returns
 */
export const DocList = (props: DocListProp) => {
  // eslint-disable-next-line
  const [openDocInfo, setOpenDocInfo] = useState<boolean>(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [openNewFolder, setOpenNewFolder] = useState<boolean>(false);
  const [openMove, setOpenMove] = useState<boolean>(false);
  const [openNameChange, setOpenNameChange] = useState<boolean>(false);
  const [openTrash, setOpenTrash] = useState<boolean>(false);
  const [useCheckbox, setUseCheckbox] = useState<boolean>(false);

  // const [selected, setSelected] = useState<DriveDto[]>([]);

  const setSelected = useSeletedStore((state) => state.setSelected)
  const getSelected = useSeletedStore((state) => state.getSelected)
  const { toastOpen } = useToast();

  const {
    driveList,
    folderMove,
    reloadData,
    nowPath
  } = useFetchDrive();

  // DriveList 의 요소가 변경되면 선택된 목록을 초기화 한다.
  useEffect(() => {
    setSelected([]);
  }, [driveList, setSelected]);
  /**
   *
   * @param id
   */
  const handleOpen = (id: string) => {
    if (id === DOC_NEW_FOLDER_ID) {
      setOpenNewFolder(true);
    } else if (id === DOC_MOVE_ID) {
      setOpenMove(true);
    } else if (id === DOC_NAME_CHANGE_ID) {
      setOpenNameChange(true);
    } else if (id === DOC_TRASH_ID) {
      setOpenTrash(true);
    } else if (id === DOC_UPLOAD_ID) {
      setOpenUpload(true);
    }
  };

  /**
   * 모달 닫기
   * @param id
   */
  const handleCancel = (id: string) => {
    if (id === DOC_NEW_FOLDER_ID) {
      setOpenNewFolder(false);
    } else if (id === DOC_MOVE_ID) {
      setOpenMove(false);
    } else if (id === DOC_NAME_CHANGE_ID) {
      setOpenNameChange(false);
    } else if (id === DOC_TRASH_ID) {
      setOpenTrash(false);
    } else if (id === DOC_UPLOAD_ID) {
      setOpenUpload(false);
    }
  };

  /**
   * 처리 성공
   * @param id
   */
  const handleCreated = (id: string) => {
    let message: string = '';

    if (id === DOC_NEW_FOLDER_ID) {
      setOpenNewFolder(false);
      message = '폴더를 생성하였습니다.';
    } else if (id === DOC_MOVE_ID) {
      setOpenMove(false);
      // {{타겟 폴더}}로 이동이 완료되었습니다.
      message = '이동이 완료되었습니다.';
    } else if (id === DOC_NAME_CHANGE_ID) {
      setOpenNameChange(false);
      message = '이름 변경이 완료되었습니다.';
    } else if (id === DOC_TRASH_ID) {
      message = '휴지통으로 이동이 완료되었습니다.';
      setOpenTrash(false);
    } else if (id === DOC_UPLOAD_ID) {
      message = '파일 업로드가 완료되었습니다.'
      setOpenUpload(false);
    }

    toastOpen(message);
    reloadData();
  };

  const handleMultiToggle = (isMulti: boolean) => {
    setUseCheckbox(isMulti);
    setSelected([]);
  };


  const handleSelected = (data: DriveDto) => {
    data.driveType = props.type

    if (useCheckbox) { // 체크박스 ... 
      const selected = getSelected()
      const index: number = selected.findIndex((v) => v.path === data.path);

      if (index < 0) {
        const rt = [...selected, data]
        setSelected(rt);
      } else {
        const rt = selected.filter((_, i: number) => i !== index)
        setSelected(rt)
      }
    } else {
      setSelected([data]);
      setOpenDocInfo(true);
    }
  };

  const handleSelectedAll = (checked: boolean) => {
    if (checked) {
      setSelected([...driveList].filter((v) => v.type !== 'none'))
    } else {
      setSelected([]);
    }
  }

  const handleFolderMove = (folder: DriveDto) => {
    folderMove(folder.path);
  };



  return (

    <Box sx={{
      position: 'realtive',
      height: '100%',
      width: '100%'
    }}>
      <FolderToolbar
        onMenuClick={handleOpen}
        onMultiToggle={handleMultiToggle}
      />
      {/* <FolderSearchToolbar
        folderName={props.type}
      /> */}
      <Box
        className="m-4 v-full"
        sx={{
          display: 'flex',
          height: `calc(100% - 190px)`
        }}>
        <FolderList
          useCheckbox={useCheckbox}
          // selected={selected}
          driveList={driveList}
          onFolderMove={handleFolderMove}
          onSelected={handleSelected}
          onSelectedAll={handleSelectedAll}
        />
        {openDocInfo && (
          <div className="ml-2">
            <FolderSidebar

              open={openDocInfo}
              // selected={selected.at(-1)}
              onMove={() => handleOpen(DOC_MOVE_ID)}
              onRename={() => handleOpen(DOC_NAME_CHANGE_ID)}
              onTrash={() => handleOpen(DOC_TRASH_ID)}
            />
          </div>
        )}
      </Box>
      <DocumentUploadModal
        open={openUpload}
        onCreated={handleCreated}
        targetType={props.type}
        onCancel={handleCancel}
      />
      <NewFolderModal
        driveType={props.type}
        target={nowPath}
        open={openNewFolder}
        onCreated={handleCreated}
        onCancel={handleCancel}
      />
      <FileMoveModal
        open={openMove}
        onCreated={handleCreated}
        onCancel={handleCancel}
        onUpdateTree={reloadData}
      />
      <FileRenameModal
        open={openNameChange}
        onCreated={handleCreated}
        onCancel={handleCancel}
      />
      <TrashMoveConfirm
        open={openTrash}
        onCreated={handleCreated}
        onCancel={handleCancel}
      />
    </Box>
  )
};