import { driveApi } from '@api';
import { DriveDto } from '@dto/DriveDto';
import { useCallback, useEffect, useState } from 'react';

/**
 *
 * @param rootPath
 * @returns
 */
function useFetchTree (targetType: 'my' | 'doc' | 'trash') {
  const [tree, setTree] = useState<DriveDto>();

  const fetchData = useCallback(
    async () => {
      try {
        if (!targetType) {
          console.log("!!!!!!!!!!!!!!!!!!!!1No Target Type.")
          throw new Error("No Target")
        }
        const { result } = await driveApi.tree('/', targetType);
        if (result.path === '') {
          result.path = '/'
        }
        setTree(result);
      } catch (error: any) {
        console.log("Error", error)
      } finally {

      }
    },
    [targetType]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const reloadTree = () => fetchData();

  return {
    tree,
    reloadTree
  };
};

export default useFetchTree;