import React from 'react';
import { useConfirm } from '@hook';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const Confirm = () => {
  const { confirm: confirmSelector, confirmClose, confirmOk } = useConfirm();
  const { isOpen, title, content, okTitle, cancelTitle } = confirmSelector;

  const handleCancel = () => {
    confirmClose();
  };

  const handleOk = () => {
    confirmOk();
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth={'xs'}
      >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleCancel}>{cancelTitle}</Button>
        <Button color="secondary" onClick={handleOk}>{okTitle}</Button>
      </DialogActions>
    </Dialog>
  )
};