import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, } from '@mui/material';
import { DOC_MOVE_ID } from '@const';
import theme from '@style/theme';
import { NewFolderModal } from './NewFolderModal';
import { useFetchTree, useProgress } from '@hook';
import { driveApi } from '@api';
import { useNavigate } from 'react-router-dom';
import { FolderTreeView } from './FolderTreeView';
import useSeletedStore from '../../store/mystore';



/**
 * onProcess => 처리 시작, 처리 중
 * onCreated => 처리 완료
 * onFailed => 처리 실패
 */
type FileMoveModalProps = DialogProps & {
  onCreated: (id: string, result?: any) => void;
  onCancel: (id: string) => void;
  onProcess?: (id: string) => void;
  onFailed?: (id: string) => void;
  onUpdateTree: () => void;
};

/**
 * 선택된 파일 이동
 * @param props
 */
export const FileMoveModal: React.FC<FileMoveModalProps> = (props) => {
  const { onCreated, onCancel, onProgress, onUpdateTree, ...other } = props;
  const [openNewFolder, setOpenNewFolder] = useState<boolean>(false);
  const [target, setTarget] = useState<string>('');
  const navigate = useNavigate();

  const items = useSeletedStore((state) => state.selected);
  const { tree, reloadTree } = useFetchTree(items[0]?.driveType);
  const { progressOpen, progressClose } = useProgress();

  useEffect(() => {
    if (other.open) {
      reloadTree();
    }
  },
    // eslint-disable-next-line
    [other.open]);

  const handleNodeSelect = (e: any, nodeIds: string) => {
    console.log('SELECT', nodeIds);
    setTarget(nodeIds);
  };

  /**
   * 처리
   */
  const handleSubmit = async () => {
    try {
      progressOpen('이동', '선택하신 파일을 이동 중입니다.');
      if (!items || items.length === 0) {
        throw new Error('선택된 파일 혹은 디렉토리가 없습니다.');
      }

      if (!target) {
        throw new Error('이동할 디렉토리가 선택되지 않았습니다.');
      }

      await Promise.all(
        items.map(async (value) => await driveApi.move([`${value.path}`], 'doc', `${target}`, value.driveType))
      );
      onCreated(DOC_MOVE_ID);
      const q = target.split('/').slice(1).join('/');
      navigate(`/doc?q=${q}`);
    } catch (error: any) {
      console.log(error);
    } finally {
      progressClose();
    }
  };

  const handleCancel = () => {
    onCancel(DOC_MOVE_ID);
  };

  const handleOpenNewFolder = () => {
    setOpenNewFolder(true);
  };

  const handleFolderCreated = () => {
    setOpenNewFolder(false);
    reloadTree();
    onUpdateTree();
    // 업데이트
  };

  const handleFolderCancel = () => {
    setOpenNewFolder(false);
  };
  let targetType = items[0]?.driveType
  if (targetType === undefined) {
    targetType = "my"
  }
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        {...other}
      >
        <DialogTitle>이동</DialogTitle>
        <DialogContent>
          <FolderTreeView
            sx={{
              width: 'auto',
              minHeight: 240,
              maxHeight: 240,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '8px',
              padding: '4px',
              overflowY: 'auto'
            }}
            driveTree={tree}
            onNodeSelect={handleNodeSelect}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'space-between'
          }}
        >
          <Button
            color="secondary"
            onClick={handleOpenNewFolder}
          >
            폴더 생성
          </Button>
          <div>
            <Button
              color="inherit"
              onClick={handleCancel}
            >
              취소
            </Button>
            <Button
              color="secondary"
              onClick={handleSubmit}
            >
              이동
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <NewFolderModal
        target={target}
        driveType={targetType}
        open={openNewFolder}
        onCreated={handleFolderCreated}
        onCancel={handleFolderCancel}
      />
    </>
  )
};
