import { useLoading } from '@hook';
import { Backdrop, CircularProgress } from '@mui/material';

export const Loading = () => {
  const { loading } = useLoading();

  return (
    <Backdrop
      open={loading.isOpen}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      >
      <CircularProgress color="secondary" size={48} />
    </Backdrop>
  )
}