import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store';

export interface LoadingState {
  isOpen: boolean;
}

const initialState: LoadingState = {
  isOpen: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },

    close: (state) => {
      state.isOpen = false;
    }
  }
});

export const selectLoading = (state: RootState) => state.loading;

export const { open, close } = loadingSlice.actions;
export default loadingSlice.reducer;
