import { useState } from 'react';
import {
  LoginForm
} from '@components/Auth/LoginForm';
import { useNavigate } from 'react-router-dom';
import { authApi } from '@api';
import { TOKEN_KEY } from '@const';
import { UserDto } from '@dto/UserDto';
import { TokenDto } from '@dto/TokenDto';
import { USER_STATE } from '@const/code';
import { useAppDispatch } from '@store/hook';
import { setUser } from '@store/user';

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const dispatch = useAppDispatch();

  /**
   * 로그인 처리
   * @param id
   * @param password
   */
  const handleLogin = async (
    id: string,
    password: string
  ) => {
    setLoading(true);
    try {
      const { result } = await authApi.login(id, password);
      console.log(result);

      const user  = result.user as UserDto;
      const token = result.token as TokenDto;

      if (user.status !== USER_STATE.ACTIVATE) {
        setError('사용할 수 없는 계정입니다.');
        return;
      }

      dispatch(setUser(user));
      localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
      setError(undefined);
      navigate('/doc');
    } catch (error: any) {
      if (!error.status) {
        setError(error.message);
      } else {
        if (error.status === 422) {
          setError('아이디 (이메일) 혹은 비밀번호가 올바르지 않습니다.');
        } else if (error.status === 400) {
          setError('잘못된 요청을 하였습니다.');
        } else {
          setError(`에러가 발생하였습니다. [${error.status}]`);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * 비밀번호 찾기로 이동
   */
  const handleFindPassword = () => {
    navigate('/findpassword');
  };

  return (
    <LoginForm
      loading={loading}
      onLogin={handleLogin}
      onFindPassword={handleFindPassword}
      error={error}
      />
  )
};