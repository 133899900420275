import admin from './admin';
import printerInfo from './printerInfo';
import org from './org';
import liveUpdate from './liveUpdate';
import cert from './cert';
import annot from './annot';
import actionLog from './actionLog';

export type UcodAdmin = {
  id: string;
  label: string;
  fields: UcodField[];
  list?: string[];
  show?: string[];
  create?: string[];
  edit?: string[];
  filters?: UcodFilter[];
  search?: UcodeSearch;
  options?: UcodOptions;
  reference?: UcodReference[];
  disableEdit?: boolean;
  disableCreate?: boolean;
  disableShow?: boolean;
  useListCheckbox?: boolean;
  listCheckboxComponentName?: string;
};

export type UcodField = {
  id: string,
  label?: string,
  pk?: boolean,
  type: 'string' | 'date' | 'number' | 'password' | 'select' | 'reference' | 'textarea' | 'json' | 'annot',
  required?: boolean,
  defaultValue?: string,
  edit?: boolean,
  format?: (value: any) => void,
};

export type UcodFilter = {
  id: string,
  reference: string | { label: string, value: string }[],
  label?: string,
};

export type UcodeSearch = {
  id: string,
  label?: string,
};

export type UcodOptions = {
  useCheckbox?: boolean,
}

export type UcodReference = {
  id: string,
  reference: string | { label: string, value: string }[]
};

const types: {
  [key: string]: UcodAdmin,
} = {
  admin,
  printerInfo,
  org,
  liveUpdate,
  cert,
  annot,
  actionLog
};

export default types;
