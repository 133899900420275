import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import { SystemLogList } from '@components/Log/SystemLogList'
import {
  PlayArrow as PlayIcon,
  Pause as PauseIcon
} from '@mui/icons-material';
import { BreadCrumb } from '@components/Common/Breadcrumb';

export const SystemLog = () => {
  const [isPause, setIsPause] = useState<boolean>(false);

  const handleToggle = () => {
    setIsPause((isPause: boolean) => !isPause);
  }

  return (
    <React.Fragment>
      <BreadCrumb />
      <Box
        pt={2}
        >
        <Box
          sx={{
            mb: 2,
            textAlign: 'right',
            px: 2
          }}>
            <Button
              variant="outlined"
              color={isPause ? 'secondary' : 'error'}
              size="small"
              startIcon={
                isPause ?
                <PlayIcon /> :
                <PauseIcon />
              }
              disableElevation
              onClick={handleToggle}
              >STREAM LIVE</Button>
        </Box>
        <SystemLogList isPause={isPause} />
      </Box>
    </React.Fragment>
  )
}