import { USE_YN } from '@const';
import { UcodAdmin } from '@type';

const Org: UcodAdmin = {
  id: 'org',
  label: '조직 관리',
  fields: [
    {
      id: 'orgId',
      label: 'ID',
      pk: true,
      type: 'string',
    },
    {
      id: 'useDaemon',
      label: '데몬 사용 여부',
      required: true,
      type: 'select',
    },
    {
      id: 'daemonIp',
      label: '데몬 IP',
      type: 'string',
    },
    {
      id: 'useYn',
      label: '사용 여부',
      type: 'select',
    },
    {
      id: 'ips',
      label: 'IPS',
      required: true,
      type: 'string',
    },
    {
      id: 'cdate',
      label: '생성일',
      type: 'date',
    },
    {
      id: 'udate',
      label: '최종 수정일',
      type: 'date',
    },
  ],
  list: ['orgId', 'useDaemon', 'daemonIp', 'useYn', 'udate'],
  show: ['orgId', 'useDaemon', 'daemonIp', 'ips', 'useYn', 'udate'],
  create: ['orgId', 'useDaemon', 'daemonIp', 'ips', 'useYn'],
  edit: ['orgId', 'useDaemon', 'daemonIp', 'ips', 'useYn'],
  filters: [
    {
      id: 'useDaemon',
      label: '데몬 사용 여부',
      reference: USE_YN,
    },
    {
      id: 'useYn',
      label: '사용여부',
      reference: USE_YN,
    },
  ],
  // 참고 정보
  reference: [
    {
      id: 'useDaemon',
      reference: USE_YN,
    },
    {
      id: 'useYn',
      reference: USE_YN,
    },
  ],
  useListCheckbox: true,
  listCheckboxComponentName: 'orgUseN',
};

export default Org;