import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

type ShowFieldProps = TypographyProps & {
  multiline?: boolean
};

/**
 * 데이터를 보여준다
 * @param props
 * @returns
 */
export const ShowField: React.FC<ShowFieldProps> = (props) => {
  const { children, multiline = false } = props;

  return (
    <Typography
      component={'div'}
      sx={{
        border: '1px solid #BDBDBD',
        borderRadius: 1,
        padding: '10px 14px',
        minHeight: '21px',
        height: multiline ? '80px' : 'auto',
        maxHeight: multiline ? '80px' : 'auto',
        overflowY: multiline ? 'auto' : 'hidden'
      }}
      fontWeight={500}
      >
      {children}
    </Typography>
  )
}