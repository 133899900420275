import React, { useCallback, useEffect, useState, useRef } from 'react'
import { VirtuosoHandle } from 'react-virtuoso';
import { TableCell, TableRow } from '@mui/material';
import { formatDate } from '@util/format';
import { FixedHeaderTable } from '@components/Common/Table/FixedHeaderTable';
import { TableHeader, HeaderCell } from '@components/Common/Table/TableHeader';

const HeaderCells: HeaderCell[] = [
  {
    id: 'timestamp',
    label: 'TIMESTAMP',
    width: '10%'
  },
  {
    id: 'message',
    label: '메시지'
  }
];

function generateData(length: number) {
  return Array.from({ length }, () => {
    return {
      timestamp: formatDate(new Date()),
      message: Array.from({ length: 100 }, () => ('Message')).join(' '),
    }
  })
}

type SystemLogListProps = {
  isPause: boolean
};

export const SystemLogList: React.FC<SystemLogListProps> = (props) => {
  const { isPause } = props;
  const [items, setItems] = useState<any>([]);
  const ref = useRef<VirtuosoHandle>(null);

  const leadMore = useCallback(() => {
    return setInterval(() => {
      setItems((items: any) => [...items, ...generateData(10)]);
    }, 2000);
  }, [setItems]);

  useEffect(() => {
    let timeout: any = undefined;

    if (isPause) {
      clearInterval(timeout);
      timeout = undefined;
    } else {
      timeout = leadMore();
    }

    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    }
  }, [isPause, leadMore]);

  /**
   * 스크롤링을 한다
   * @param e
   */
  const handleScroll = (e: any) => {
  };

  return (
    <FixedHeaderTable
      ref={ref}
      style={{
        height: `calc(100vh - (64px + 49px + 36.5px + 32px))`
      }}
      components={
        {
          TableRow: ({ item: _item, ...props }) => (
            <TableRow
              {...props}
              />
          )
        }
      }
      fixedHeaderContent={
        () => (
          <TableHeader
            headerCells={HeaderCells}
            numSelected={0}
            rowCount={0}
            totalRowCount={0}
            />
        )
      }
      itemContent={(_: number, rowData: any) => {
        return (
          <>
            <TableCell sx={{ verticalAlign: 'top', lineHeight: 1.43 }}>{rowData.timestamp}</TableCell>
            <TableCell sx={{ lineHeight: 1.43 }}>{rowData.message}</TableCell>
          </>
        )
      }}
      onScroll={handleScroll}
      data={items}
      overscan={200}
      />
  )
};
