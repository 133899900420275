import React from 'react';
import { Button, Divider, Toolbar } from '@mui/material';
import type { ToolbarProps } from '@mui/material';
import { useDocToolbarMenus } from '@hook';

import useSeletedStore from '../../store/mystore';
// import { DOC_SELECTED, SIDEBAR_WIDTH } from '@const';
// import { CheckBoxOutlined as CheckBoxIcon } from '@mui/icons-material';

type FolderToolbarProps = ToolbarProps & {
  // selected?: 'none' | 'single' | 'multi';
  onMenuClick: (id: string) => void;
  onMultiToggle?: (isMulti: boolean) => void;
};

/**
 * 문서 툴바
 * @param props
 * @returns
 */
export const FolderToolbar: React.FC<FolderToolbarProps> = (props) => {
  const { onMenuClick, onMultiToggle, ...other } = props;
  const selected = useSeletedStore((state) => state.selectedType())
  console.log("folder toolbar ----------------------------", selected)
  // const menus: any[] = []
  const [menus] = useDocToolbarMenus(selected);
  // const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log(e.currentTarget.id);
    onMenuClick(e.currentTarget.id);
  };

  // const handleMultiSelect = () => {
  //   if (onMultiToggle) {
  //     setIsMultiSelect(!isMultiSelect);
  //     onMultiToggle(!isMultiSelect);
  //   }
  // };

  return (
    <>
      <Toolbar
        variant="dense"
        sx={{
          // position: 'fixed',

          // top: 64,
          backgroundColor: 'white',
          // zIndex: 10,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,

        }}
        {...other}
      >
        {menus.map((menu: any, index: number) => (
          <React.Fragment key={`toolbar-${index}`}>
            {menu.id === 'divider' && (
              <Divider
                variant="middle"
                orientation="vertical"
                flexItem
                sx={{ mx: 1 }}
              />
            )}
            {menu.id !== 'divider' && (
              <Button
                id={menu.id}
                color="inherit"
                sx={{ minWidth: '86px' }}
                startIcon={menu.icon}
                onClick={handleClick}
              >
                {menu.label}
              </Button>
            )}
          </React.Fragment>
        ))}
        {/* {onMultiToggle && (
          <Button
            variant={isMultiSelect ? 'outlined' : 'contained'}
            color="secondary"
            disableElevation
            sx={{ ml: 'auto' }}
            onClick={handleMultiSelect}
          >
            {isMultiSelect ? '취소' : '선택'}
          </Button>
        )} */}
      </Toolbar>
    </>
  );
};
