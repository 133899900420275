import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';

export interface ProgressState {
  isOpen: boolean;
  title?: string | null;
  contentText?: string | null;
}

const initialState: ProgressState = {
  isOpen: false,
  title: null,
  contentText: null,
};

export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ProgressState>) => {
      const { isOpen, title, contentText } = action.payload;

      state.isOpen = isOpen;
      state.title = title;
      state.contentText = contentText;
    },

    close: (state) => {
      state.isOpen = false;
      state.title = null;
      state.contentText = null;
    }
  }
});

export const selectProgress = (state: RootState) => state.progress;

export const { open, close } = progressSlice.actions;
export default progressSlice.reducer;
