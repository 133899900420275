import { TARGET_TYPE, USE_YN } from '@const';
import { UcodAdmin } from '@type';

const printerInfo: UcodAdmin = {
  id: 'printerInfo',
  label: '사용불가 프린터 관리',
  fields: [
    {
      id: 'targetName',
      label: '프린터 이름',
      pk: true,
      required: true,
      type: 'string',
    },
    {
      id: 'targetType',
      label: '미사용 검증 타겟',
      pk: true,
      required: true,
      type: 'select'
    },
    {
      id: 'useYn',
      label: '사용여부',
      type: 'select',
      required: true,
      defaultValue: 'N',
    },
    {
      id: 'description',
      label: '설명',
      type: 'textarea'
    },
    {
      id: 'cdate',
      label: '생성일',
      type: 'date'
    },
    {
      id: 'udate',
      label: '최종 수정일',
      type: 'date'
    },
  ],
  list: ['targetName', 'targetType', 'useYn', 'udate'],
  show: ['targetName', 'targetType', 'useYn', 'description'],
  create: ['targetName', 'targetType', 'useYn', 'description'],
  edit: ['targetName', 'targetType', 'useYn', 'description'],
  filters: [
    {
      id: 'targetType',
      label: '미사용 검증 타겟',
      reference: TARGET_TYPE
    },
    {
      id: 'useYn',
      label: '사용여부',
      reference: USE_YN
    }
  ],
  reference: [
    {
      id: 'useYn',
      reference: USE_YN
    },
    {
      id: 'targetType',
      reference: TARGET_TYPE
    }
  ]
};

export default printerInfo;
