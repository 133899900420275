import { colors, createTheme } from '@mui/material';
import { common, blue } from '@mui/material/colors';

export default createTheme({
  palette: {
    primary: {
      main: common.white,
      light: '#F0F7FF'
    },
    secondary: {
      main: blue['700']
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          padding: '8px 16px',
          color: 'inherit',
          height: 25
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.MuiTableRow-hover:hover`]: {
            backgroundColor: '#F3F6F9'
          },
          [`&.Mui-selected`]: {
            backgroundColor: '#F0F7FF',
            color: '#0072E5'
          },
          [`&.Mui-selected:hover`]: {
            backgroundColor: 'rgba(0, 127, 255, 0.12)',
            color: 'inherit'
          },
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey[100]
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'primary' && (
            {
              borderRadius: '8px',
              [`&:hover`]: {
                backgroundColor: '#F3F6F9',
              }
            }
          )),
        })
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          [`&.Mui-selected`]: {
            backgroundColor: '#F0F7FF',
            color: '#0072E5'
          },
          [`&.Mui-selected:hover`]: {
            backgroundColor: 'rgba(0, 127, 255, 0.12)',
            color: 'inherit'
          },
          [`&:hover`]: {
            backgroundColor: '#F3F6F9',
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'body1' || ownerState.variant === 'body2') && {
            fontSize: '0.875rem',
            fontWeight: 600,
          }
        })
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 500,
          [`& .MuiInputBase-input`]: {
            padding: '10px 14px'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 500,
          [`& .MuiInputBase-input`]: {
            padding: '10px 14px'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 500,
          [`& .MuiInputBase-input`]: {
            padding: '10px 14px'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: `0 !important`
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          [`&.Mui-expanded`]: {
            margin: 0
          },
          [`&:before`]: {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 1rem'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 600,
          [`&.Mui-expanded`]: {
            minHeight: 48,
          }
        },
        content: {
          [`&.Mui-expanded`]: {
            margin: 0
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          cursor: 'pointer',
          textDecoration: 'none'
        }
      }
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Apple SD Gothic Neo"',
      '"Pretendard Variable"',
      'Pretendard',
      'Roboto',
      '"Noto Sans KR"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  }
});
