import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Type, { UcodField } from '@type';
import { useLoading, useToast } from '@hook';
import { Box, Button, Container, FormControl } from '@mui/material';
import {
  List as ListIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '@api';
import { unflatten } from 'flat';
import { FormLabel } from '@components/Common/Form/FormLabel';
import { Select } from '@components/Common/Form/Select';
import { TextField } from '@components/Common/Form/TextField';
import { BreadCrumb } from '@components/Common/Breadcrumb';

export const CommonCreate = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [fields, setFields] = useState<UcodField[]>([]);
  const { loadingOpen, loadingClose } = useLoading();
  const { toastOpen } = useToast();

  let scheme: { [key: string]: any } = {};
  let defaultValues: { [key: string]: any } = {};

  if (params.id) {
    const { fields, create } = Type[params.id];
    let fs: UcodField[] = [];

    if (create) {
      fs = fields.filter((f) => create.includes(f.id));
    } else {
      fs = fields;
    }

    scheme = {};

    fs.forEach((f: UcodField) => {
      const label: string = f.label ? f.label : f.id.toUpperCase();
      if (f.required || f.pk) {
        scheme[f.id] = yup.string().required(`${label}을(를) 입력해주세요.`);
      } else {
        scheme[f.id] = yup.string().notRequired();
      }
      defaultValues[f.id] = '';
      });
  }

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(scheme))
  });

  useEffect(
    () => {
      if (params.id) {
        const { fields, create, disableCreate = false } = Type[params.id];

        if (disableCreate) {
          navigate(`/${params.id}`);
        }

        if (create) {
          setFields(fields.filter((f) => create.includes(f.id)));
        } else {
          setFields(fields);
        }
      }
    },
    // eslint-disable-next-line
    [params]
  );

  const handleClickList = () => {
    navigate(`/${params.id}`);
  };

  const handleSubmit = async (data: any) => {
    try {
      loadingOpen();
      const key = params.id + 'Api';
      const p = unflatten(data);
      await api[key].create(p);
      toastOpen('등록에 성공하였습니다.');
      navigate(`/${params.id}`);
    } catch (error: any) {

    } finally {
      loadingClose();
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb />
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
            mb: 2
          }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClickList}
              startIcon={<ListIcon />}
              >
                목록으로
            </Button>
        </Box>
        <Box
          component="form"
          onSubmit={onSubmit(handleSubmit)}
          >
          {fields.map((f: UcodField) => (
            <FormControl
              key={`form-${f.id}`}
              fullWidth
              sx={{ mb: 2 }}
              >
              <FormLabel
                field={f}
                errors={errors[f.id] ? true : false}
                />
              {f.type === 'select'  && (
                <Select
                  field={f}
                  register={register(f.id)}
                  error={errors[f.id] ? true : false}
                  helperText={errors[f.id]?.message as string}
                  defaultValue={''}
                  />
              )}
              {f.type !== 'select' && (
                <TextField
                  field={f}
                  register={register(f.id)}
                  error={errors[f.id] ? true : false}
                  helperText={errors[f.id]?.message as string}
                  rows={f.type === 'textarea' ? 4 : 1}
                  />
              )}
            </FormControl>
          ))}
          <Box
            sx={{
              mt: 4,
              mb: 6,
              textAlign: 'center'
            }}
            >
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disableElevation
              size="large"
              startIcon={<AddIcon />}
              >
              저장
            </Button>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};
