import { useToast } from '@hook';
import {
  Snackbar,
  IconButton
} from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';

/**
 * 결과에 대한 토스트 (스낵바)
 * @returns
 */
export const Toast = () => {
  const {
    toast,
    toastClose
  } = useToast();

  return (
    <Snackbar
      open={toast.isOpen}
      autoHideDuration={3000}
      onClose={toastClose}
      message={toast.message}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={toastClose}
          >
            <CloseIcon fontSize="small" />
        </IconButton>
      } />
  );
};