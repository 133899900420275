import React from 'react';
import { Box, Checkbox, TableCell, TableRow } from '@mui/material';
// import { twMerge } from 'tailwind-merge'
import {
  FolderOutlined as FolderIcon,
  ArticleOutlined as FileIcon,
} from '@mui/icons-material';
import { DriveDto } from '@dto/DriveDto';
import { formatDate } from '@util/format';
import { FixedHeaderTable } from '@components/Common/Table/FixedHeaderTable';
import { HeaderCell, TableHeader } from '@components/Common/Table/TableHeader';
import { DocumentMenu } from './DocumentMenu';
import useSeletedStore from '../../store/mystore';

const HeaderCells: HeaderCell[] = [
  {
    id: 'name',
    label: '파일명',
    width: 'auto'
  },
  {
    id: 'uDate',
    label: '최근 수정일',
    width: '20%'
  },
  {
    id: 'size',
    label: '크기',
    width: '10%'
  },
  {
    id: 'more',
    label: '',
    width: '5%'
  }
];

type FolderListProps = {
  className?: string;
  useCheckbox?: boolean;
  useMore?: boolean;
  driveList?: DriveDto[];
  onFolderMove: (drive: DriveDto) => void;
  onSelected: (drive: DriveDto) => void;
  onSelectedAll: (checked: boolean) => void;
};

export const FolderList: React.FC<FolderListProps> = (props) => {
  const {

    useCheckbox = false,
    useMore = false,
    driveList = [],
    onFolderMove,
    onSelected,
    onSelectedAll,
  } = props;
  console.log("render FolderList", useMore)
  const isSelected = useSeletedStore((state) => state.isSelected);
  let clickTimeout: any = undefined;

  const clearClickTimeout = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      clickTimeout = undefined;
    }
  };

  /**
   * ROW 클릭 이벤트
   *
   * @param event
   * @param rowData
   */
  const handleRowClick = (event: any, rowData: any) => {
    clearClickTimeout();
    console.log("HandRowClic", rowData, event)
    if (event.detail === 1) {
      clickTimeout = setTimeout(() => {
        if (rowData.type === 'none') {
          return;
        }
        onSelected(rowData);
      }, 200);
    }

    if (event.detail % 2 === 0 && rowData.type !== 'file') {
      console.log('ROW DATA >> ', rowData);
      onFolderMove(rowData);
    }
  };

  const selectedItem = (path: string): boolean => {

    const rt = isSelected(path);
    // console.log("selected", rt, isSelected(path), path)
    return rt
  }

  const handleChecked = (data: any) => {
    onSelected(data);
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.checked);
    onSelectedAll(e.target.checked);
  }

  return (

    <FixedHeaderTable
      style={{
        height: `100%`
      }}
      components={
        {
          TableRow: ({ item: _item, ...props }) => {
            // 아래의 구문이 있어야 선택값이 반영된다.
            // 바깥쪽에 있으면 리스트가 다시 랜더링 되서 ,스크롤이 엉망이 된다.
            useSeletedStore((state) => state.selected);
            return (
              <TableRow
                hover
                sx={{ cursor: 'pointer' }}
                {...props}
                onClick={(e) => {
                  handleRowClick(e, _item);
                }}
                selected={selectedItem(_item.path)}
              />
            )
          }
        }
      }
      fixedHeaderContent={
        () => {
          let headerCells: HeaderCell[] = [];

          if (!useMore) {
            headerCells = HeaderCells.slice(0, 3);
          } else {
            headerCells = HeaderCells;
          }

          return (
            <TableHeader
              headerCells={headerCells}
              numSelected={0}
              rowCount={driveList.filter((rowData) => rowData.type !== 'none').length}
              totalRowCount={driveList.filter((rowData) => rowData.type !== 'none').length}
              useCheckbox={useCheckbox}
              onSelectAllClick={handleSelectAll}
            />
          )
        }
      }
      itemContent={
        (index: number, rowData: DriveDto) => {

          return (

            <DriveCell
              key={index}
              useCheckbox={useCheckbox}
              rowData={rowData}
              onChecked={handleChecked}

            // selected={selectedItem(rowData.path)}
            />
          )
        }
      }
      data={driveList}
    />
  )
};

type DriveCellProps = {
  rowData: DriveDto,
  useCheckbox?: boolean,
  useMore?: boolean,
  selected?: boolean,
  onChecked: (rowData: DriveDto) => void,
};

const DriveCell: React.FC<DriveCellProps> = (props) => {
  const {
    rowData,
    useMore = false,
    useCheckbox = false,
    selected = false,
    onChecked
  } = props;

  const handleChecked = (e: any) => {
    e.stopPropagation();
    onChecked(rowData);
  }

  if (rowData.type === 'none') {
    let colsCount: number = 4;

    if (!useMore) {
      colsCount -= 1;
    }

    return (
      <>
        {useCheckbox && (
          <TableCell padding="checkbox"></TableCell>
        )}
        <TableCell colSpan={colsCount}>
          {`...`}
        </TableCell>
      </>
    )
  } else {
    return (
      <>
        {useCheckbox && (
          <TableCell
            padding="checkbox">
            <Checkbox
              color="secondary"
              sx={{ padding: 0 }}
              onClick={handleChecked}
              checked={selected}
            />
          </TableCell>
        )}
        <TableCell>
          <Box sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center'
          }}>
            {(rowData.type === 'directory' ? <FolderIcon sx={{ mr: 2 }} /> : <FileIcon sx={{ mr: 2 }} />)}
            {rowData.name}
          </Box>
        </TableCell>
        <TableCell>{formatDate(rowData.uDate)}</TableCell>
        <TableCell>
          {rowData.type === 'file' ? <span>{rowData.size}</span> : ''}
        </TableCell>
        {useMore && (
          <TableCell>
            <DocumentMenu
              dataId={rowData.path}
              onClickMenu={() => { }}
            />
          </TableCell>
        )}
      </>
    )
  }
};