import ResultDto from '@dto/ResultDto';
import instance from './instance';
import { DriveDto } from '@dto/DriveDto';
import DetailResultDto from '@dto/DetailResultDto';

/**
 * 패스
 * @param path
 * @returns
 */
export const find = async (path: string, type: 'my' | 'doc' | 'trash'): Promise<ResultDto<DriveDto>> => {
  return await instance.post('/drive', {
    path,
    type
  })
};

/**
 * 폴더 생성
 * @returns
 */
export const mkdir = async (target: string, name: string, type: string): Promise<ResultDto<DriveDto>> =>
  await instance.post('/drive/mkdir', {
    target,
    name,
    type
  });

/**
 *
 * @param path
 * @returns
 */
export const tree = async (path: string, type: 'my' | 'doc' | 'trash'): Promise<ResultDto<DriveDto>> =>
  await instance.post('/drive/tree', {
    path,
    type
  });


/**
 * 이름 변경
 * @param target 
 * @param newName 
 * @returns 
 */
export const rename = async (target: string, newName: string, type: string) =>
  await instance.post('/drive/rename', {
    target,
    newName,
    type
  });

/**
 * 파일 이동
 * @param paths - 선택된 파일 
 * @param targetDir - 이동 되어질 디렉토리
 * @returns 
 */
export const move = async (paths: string[], pathType: string, target: string, targetType: string) =>
  await instance.post('/drive/move', {
    paths,
    pathType,
    target,
    targetType,
  });


/**
 * 
 * @param target 
 * @returns 
 */
export const docInfo = async (target: string, type: string): Promise<ResultDto<DetailResultDto<any>>> =>
  await instance.post('/drive/docInfo', { target, type });

/**
 * 
 * @param target 
 * @param page 
 * @returns 
 */
export const thumbnail = async (target: string, page: number, type: string): Promise<any> =>
  await instance.post('/drive/thumbnail', {
    target,
    page,
    type
  }, {
    responseType: 'arraybuffer'
  });

export const remove = async (paths: string[], type: String): Promise<any> =>
  await instance.post('/drive/remove', {
    paths,
    type
  });

export const uploads = async (formData: FormData) => {
  return await instance.post('/drive/uploads', formData);
};


export const regDoc = async (target: string, type: string, options?: any, isLite?: boolean) => {
  const keys = Object.keys(options);
  const newObj = {} as any
  keys.map((key: any) => {
    if (key === "noexpire") {
      newObj["useLimit"] = -90
    } else {
      newObj["feature:" + key] = options[key]
    }
    return true
  })
  return await instance.post('/drive/regDoc', {
    target,
    type,
    isLite,
    options: newObj
  })
}