// import { UserDto } from '@dto/UserDto';
import ResultDto from '@dto/ResultDto';
import instance from './instance';
import ListResultDto from '@dto/ListResultDto';
import { UserDto } from '@dto/UserDto';
import DetailResultDto from '@dto/DetailResultDto';

/**
 * 사용자 목록
 * @param page
 * @param keyword
 * @param role
 * @param status
 * @returns
 */
export const find = async (
  page: number,
  params: {
    keyword?: string,
    ['role.id']: readonly string[],
    status?: readonly string[]
  }
): Promise<ResultDto<ListResultDto<UserDto>>> =>
  await instance.get(`/user`, { params: {
    page,
    keyword: params.keyword,
    role: params['role.id'],
    status: params.status
  } });

export const findByOne = async (
  { uuid }: { uuid: string }
): Promise<ResultDto<DetailResultDto<UserDto>>> =>
await instance.get(`/user/${uuid}`);

/**
 *
 * @param params
 * @returns
 */
export const create = async (
  params: UserDto
): Promise<ResultDto<any>> =>
await instance.post('/user', params);

/**
 *
 * @param params
 * @returns
 */
export const edit = async (
  params: UserDto
): Promise<ResultDto<any>> =>
  await instance.patch(`/user/${params.uuid}`, params);