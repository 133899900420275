import React from 'react';
import { useAlert } from '@hook';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

/**
 * 공통 알럿
 * @returns
 */
export const Alert = () => {
  const { alert: alertSelector, alertOk } = useAlert();
  const { isOpen, title, content, okTitle } = alertSelector;

  const handleOk = () => {
    alertOk();
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth={'xs'}
      >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleOk}>{okTitle}</Button>
      </DialogActions>
    </Dialog>
  )
};