import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROOT_PATH } from '@const';

function usePathname () {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/') {
      navigate(ROOT_PATH);
    }
  }, [pathname, navigate]);
};

export default usePathname;