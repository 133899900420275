import React from 'react';
import type { SxProps, Theme } from '@mui/material';
import { AuthWrapper } from './AuthWrapper';
import { Box, TextField, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from '@mui/lab';

const inputSx: SxProps<Theme> = {
  [`.MuiInputBase-root`]: {
    fontSize: '0.875rem'
  }
};

interface FindPasswordFormProps {
  onFindPassword: (id: string) => void;
  onLogin: () => void;
}

/**
 * 비밀번호 찾기
 * @returns
 */
export const FindPasswordForm: React.FC<FindPasswordFormProps> = (props) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.onFindPassword('');
  };

  const handleLogin = () => {
    props.onLogin();
  };

  return (
    <AuthWrapper
      pageName="비밀번호 찾기"
      >
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        >
        <Grid container spacing={2}>
          <Grid xs={12}>              
            <TextField
              variant="outlined"
              color="secondary"
              size="small"
              fullWidth
              placeholder="아이디"
              hiddenLabel
              sx={inputSx}
              />
          </Grid>
          <Grid xs={12}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              size="medium"
              loadingPosition={'start'}
              disableElevation
              >
                비밀번호 찾기
            </LoadingButton>
          </Grid>
          <Grid xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              size="medium"
              disableElevation
              onClick={handleLogin}
              >
                로그인
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AuthWrapper>
  )
};