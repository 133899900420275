import { create } from 'zustand'
import { DriveDto } from '../dto/DriveDto'

interface SelectState {
  selected: DriveDto[];
  setSelected: (sel: DriveDto[]) => void;
  selectedType: () => string;
  getSelected: () => DriveDto[];
  isSelected: (value: string) => boolean;
}

// const selectType = (selected: DriveDto[]) => {

//   if (selected.length === 0) {
//     return 'none'
//   } else if (selected.length === 1) {
//     return 'single';
//   } else {
//     return 'multi';
//   }
// }
const useSeletedStore = create<SelectState>((set, get) => ({
  selected: [],
  setSelected: (items) => {
    console.log("SetSelected", items)
    set((state) => ({ selected: items })
    )
  },
  // test: null,
  selectedType: () => {
    const selected = get().selected
    // console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXX", selected)
    if (selected.length === 0) {
      return 'none'
    } else if (selected.length === 1) {
      return 'single';
    } else {
      return 'multi';
    }
  },
  getSelected: () => {
    return get().selected
  },
  isSelected: (value: string) => {
    const selectedItem = get().selected.findIndex((item) => item.path === value) > -1;
    // console.log("isSelected", get().selected, value)
    return selectedItem
  }
}))

export default useSeletedStore