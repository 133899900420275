import React, { forwardRef } from 'react';
import {
  FixedHeaderContent,
  ItemContent,
  TableComponents,
  TableVirtuoso,
  TableVirtuosoHandle
} from 'react-virtuoso';
import {
  Paper,
  Table,
  // TableHead,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material';


interface FixedHeaderTableProps {
  style?: React.CSSProperties,
  components?: TableComponents<any, any>;
  fixedHeaderContent?: FixedHeaderContent;
  itemContent?: ItemContent<any, any>;
  data?: readonly any[];
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  overscan?: number | { main: number, reverse: number } | undefined;
}

/**
 * 헤더 고정형 목록
 * @param props
 * @returns
 */
export const FixedHeaderTable =
  forwardRef<TableVirtuosoHandle, FixedHeaderTableProps>(
    (props: FixedHeaderTableProps,
      ref: React.ForwardedRef<TableVirtuosoHandle>) => {
      const {
        style,
        components,
        fixedHeaderContent,
        itemContent,
        data,
        onScroll,
        overscan = 0
      } = props;

      return (

        <TableVirtuoso
          ref={ref}
          style={style}
          components={
            {
              Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
                <TableContainer
                  ref={ref}
                  component={Paper}
                  {...props}
                />
              )),
              Table: (props) => (
                <Table
                  {...props}
                  sx={{ tableLayout: 'fixed' }}
                />
              ),

              TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
                <TableHead
                  ref={ref}
                  {...props}
                />
              )),
              TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
                <TableBody
                  ref={ref}
                  {...props}
                />
              )),
              ...components
            }
          }
          fixedHeaderContent={fixedHeaderContent}
          itemContent={itemContent}
          data={data}
          onScroll={onScroll}
          overscan={overscan}
        />
      );
    }
  );