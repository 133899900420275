import ResultDto from '@dto/ResultDto';
import instance from './instance';
import ListResultDto from '@dto/ListResultDto';
import { OrgInfoDto } from '@dto/OrgInfoDto';
import UpdateResultDto from '@dto/UpdateResultDto';

/**
 * 조직 목록
 * @param page
 * @returns
 */
export const find = async (
  page: number,
  {
    useDaemon,
    useYn,
  }: {
    useDaemon?: readonly string[];
    useYn?: readonly string[];
  },
): Promise<ResultDto<ListResultDto<OrgInfoDto>>> =>
  await instance.get('/orgInfo', {
    params: {
      page,
      useDaemon,
      useYn,
    },
  });

export const findAll = async (): Promise<ResultDto<ListResultDto<OrgInfoDto>>> =>
  await instance.get('/orgInfo', {
    params: {
      page: 1,
      limit: 1000,
    },
  });

export const findByOne = async ({
  orgId,
}: {
  orgId: string;
}): Promise<ResultDto<ListResultDto<OrgInfoDto>>> => await instance.get(`/orgInfo/${orgId}`);

/**
 *
 * @returns
 */
export const create = async (params: OrgInfoDto): Promise<ResultDto<any>> =>
  await instance.post(`/orgInfo`, params);

export const updateUse = async (orgId: readonly string[]): Promise<ResultDto<UpdateResultDto>> =>
  await instance.patch('/orgInfo/updateUse', { orgId });

export const edit = async (params: OrgInfoDto): Promise<ResultDto<UpdateResultDto>> => {
  const { orgId, ...other } = params;
  return await instance.patch(`/orgInfo/${orgId}`, other);
};