import { ADMIN_ROLE, ADMIN_STATUS } from '@const';
import { UcodAdmin } from '@type';

const Admin: UcodAdmin = {
  id: 'admin',
  label: '관리자 관리',
  fields: [
    {
      id: 'uuid',
      label: 'UUID',
      pk: true,
      type: 'string'
    },
    {
      id: 'email',
      label: '이메일',
      required: true,
      type: 'string'
    },
    {
      id: 'name',
      label: '이름',
      required: true,
      type: 'string'
    },
    {
      id: 'password',
      label: '비밀번호',
      type: 'password'
    },
    {
      id: 'role.id',
      label: '권한',
      type: 'select',
      required: true
    },
    {
      id: 'status',
      label: '상태',
      type: 'select'
    },
    {
      id: 'cdate',
      label: '생성일',
      type: 'date'
    },
    {
      id: 'udate',
      label: '최종 수정일',
      type: 'date'
    },
  ],
  list: [
    'email',
    'name',
    'role.id',
    'status',
    'udate'
  ],
  create: [
    'email',
    'name',
    'role.id',
    'status'
  ],
  show: [
    'uuid',
    'email',
    'name',
    'role.id',
    'status',
    'udate'
  ],
  edit: [
    'uuid',
    'email',
    'name',
    'role.id',
    'status',
  ],
  filters: [
    {
      id: 'role.id',
      label: '권한',
      reference: ADMIN_ROLE
    },
    {
      id: 'status',
      label: '상태',
      reference: ADMIN_STATUS
    }
  ],
  // 참고 정보
  reference: [
    {
      id: 'role.id',
      reference: ADMIN_ROLE
    },
    {
      id: 'status',
      reference: ADMIN_STATUS
    }
  ]
}

export default Admin;