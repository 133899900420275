import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import Theme from './style/theme'
import {
  useCheckPermission,
  useCheckSession,
  usePathname
} from '@hook';
import { Progress } from '@components/Common/Progress';
import { Toast } from '@components/Common/Toast';
import { Confirm } from '@components/Common/Confirm';
import { Loading } from '@components/Common/Loading';
import { Alert } from '@components/Common/Alert';

function App () {
  usePathname();
  useCheckSession();
  useCheckPermission();

  return (
    <ThemeProvider theme={Theme}>
      <Outlet />
      <Progress />
      <Toast />
      <Confirm />
      <Loading />
      <Alert />
    </ThemeProvider>
  );
}

export default App;
