import React, { useState } from 'react';
import type { ButtonProps } from '@mui/material';
import { Popover, Button, List } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

export interface MenuProps extends ButtonProps {
  label: string;
  autoClose?: boolean;
}

/**
 * 메뉴
 * @param
 * @returns
 */
export const Menu: React.FC<MenuProps> = ({ autoClose = false, ...props }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { children } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * children 속성에서 onClick 이벤트를 주입하여 close가 되도록 되게 한다.
   */
  const items = React.Children.map(children, (child: any) => {
    return React.cloneElement(child, {
      onClick () {
        if (autoClose) {
          setAnchorEl(null);
        }

        if (child.props.onClick) {
          child.props.onClick();
        }
      }
    });
  });

  return (
    <>
      <Button
        variant="text"
        color="inherit"
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={handleClick}
        {...props}
        >
        {props.label}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        >
        <List dense>{items}</List>
      </Popover>
    </>
  )
};
