import React from 'react';
import { Box } from '@mui/material';

/**
 * 프로필 (비밀번호 수정)
 * @returns
 */
export const Profile = (): JSX.Element => {
  return (
    <Box>
      Profile
    </Box>
  )
};