import { takeLatest, race, take } from 'redux-saga/effects';
import { open, close, ok } from './alert';

/**
 * OK가 눌렸는지 확인한다.
 */
export function* handleOk(action: ReturnType<typeof open>) {
  const { onOk } = yield race({ onOk: take(ok), okCancel: take(close) });

  if (onOk) {
    action.payload.onOk?.();
  }
}

export default function* watchOpen() {
  yield takeLatest(open.type, handleOk);
}
