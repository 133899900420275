import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import type { DialogProps } from '@mui/material';
import { DOC_NAME_CHANGE_ID } from '@const';
import { useAlert, useProgress } from '@hook';
import { driveApi } from '@api';
import useSeletedStore from '../../store/mystore';

type FileRenameModalProps = DialogProps & {
  onCancel: (id: string) => void;
  onCreated: (id: string) => void;
  onFailed?: (id: string) => void;
  onProcess?: (id: string) => void;
};

/**
 * 새폴더 생성 다이얼로그
 */
export const FileRenameModal: React.FC<FileRenameModalProps> = (props) => {
  const { onCancel, onCreated, onFailed, onProcess, ...others } = props;
  const { progressOpen, progressClose } = useProgress();
  const { alertOpen } = useAlert();

  const selected = useSeletedStore((state) => state.selected)
  const scheme = yup.object().shape({
    name: yup.string().required('변경하실 이름을 입력해주세요.')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(scheme)
  })

  const handleClose = () => {
    onCancel(DOC_NAME_CHANGE_ID);
  };

  useEffect(() => {
    if (!others.open) {
      setValue('name', '');
    } else {
      if (selected) {
        setValue('name', selected[0]?.name);
      }
    }
  }, [others.open, setValue, selected]);

  const handleSubmitProc = async (data: any) => {
    try {
      progressOpen(
        '이름 변경',
        '이름을 변경하고 있습니다.'
      );

      if (!selected) {
        throw new Error('선택된 변경 대상이 없습니다.');
      }

      const target: string = `${selected[0].path}`;

      // const filename: string = target.split('/').pop() as string;

      // const targetExt: string | undefined = filename.split('.').pop();
      // const newNameExt: string | undefined = data.name.split('.').pop();

      // if (targetExt && newNameExt) {
      //   if (targetExt !== newNameExt) {
      //     confirmOpen({
      //       title: '이름 변경',
      //       content: '확장자를 변경하시겠습니까?',
      //       onOk: async () => {
      //         await driveApi.rename(target, data.name);
      //         onCreated(DOC_NAME_CHANGE_ID);
      //       }
      //     });
      //     return;
      //   }
      // }

      await driveApi.rename(target, data.name, selected[0].driveType);
      onCreated(DOC_NAME_CHANGE_ID);
    } catch (error: any) {
      let content: string = '변경 중 오류가 발생하였습니다.';

      if (error.statusCode) {
        if (error.statusCode === 400) {
          content = '이미 존재하는 이름입니다.';
        }
      }

      alertOpen({
        title: '오류',
        content
      })

    } finally {
      progressClose()
    }
  };


  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      {...others}>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(handleSubmitProc)}
      >
        <DialogTitle>
          이름 변경
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            color="secondary"
            size="small"
            fullWidth
            placeholder="변경하실 이름을 입력해주세요."
            hiddenLabel
            {...register('name')}
            error={errors.name ? true : false}
            helperText={errors.name?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={handleClose}
          >
            취소
          </Button>
          <Button
            type="submit"
            color="secondary"
          >
            변경
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
};
