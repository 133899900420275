import { useProgress } from '@hook';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

/**
 * LOADING 처리
 */
export const Progress = () => {
  const { progress } = useProgress();
  // const [hideBackdrop, setHideBackdrop] = useState<boolean>(false);

  // useEffect(() => {
  //   if (progress.isOpen) {
  //     const dialogs = document.querySelectorAll('.MuiDialog-container');
  //     setHideBackdrop(() => dialogs.length > 0);
  //   }
  // }, [progress.isOpen]);

  return (
    <Dialog
      open={progress.isOpen}
      fullWidth
      maxWidth={'xs'}
    >
      <DialogTitle>{progress.title}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', textAlign: 'center', mb: 2 }}>
          <CircularProgress color="secondary" size={64}></CircularProgress>
        </Box>
        <DialogContentText sx={{ textAlign: 'center' }}>
          {progress.contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
};
