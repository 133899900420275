import { FILTER_LOG_STATUS, FILTER_LOG_TYPE } from "@const";
import { UcodAdmin } from "@type";

const ActionLog: UcodAdmin = {
  id: 'actionLog',
  label: '로그 관리',
  fields: [
    {
      id: 'udate',
      label: 'TIMESTEMP',
      type: 'date'
    },
    {
      id: 'uid',
      type: 'number',
      pk: true
    },
    {
      id: 'certId',
      type: 'string'
    },
    {
      id: 'actionType',
      label: '작업 종류',
      type: 'string'
    },
    {
      id: 'startPage',
      label: '시작 페이지',
      type: 'number'
    },
    {
      id: 'endPage',
      label: '종료 페이지',
      type: 'number'
    },
    {
      id: 'status',
      label: '작업 종류',
      type: 'string'
    },
    {
      id: 'retry',
      label: '재시도',
      type: 'number'
    },
    {
      id: 'printCnt',
      label: '출력 페이지 수',
      type: 'number'
    },
    {
      id: 'printOpt',
      label: '출력 옵션',
      type: 'string'
    },
    {
      id: 'cdate',
      label: '생성일',
      type: 'date'
    }
  ],
  list: ['certId', 'actionType', 'status', 'udate'],
  show: ['certId', 'actionType', 'status', 'startPage', 'endPage', 'printCnt', 'printOpt', 'udate'],
  disableCreate: true,
  disableEdit: true,
  filters: [
    {
      id: 'actionType',
      label: '작업 종류',
      reference: FILTER_LOG_TYPE
    },
    {
      id: 'status',
      label: '상태',
      reference: FILTER_LOG_STATUS
    }
  ]
};

export default ActionLog;