import React from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { Menu } from '@components/Common/Menu';
import useUser from '@hook/useUser';
import { useProgress } from '@hook';
import { authApi } from '@api';
import { TOKEN_KEY } from '@const';

/**
 * 프로필 메뉴
 * @returns
 */
export const ProfileMenu = () => {
  const navigate = useNavigate();
  const { user, clearUser } = useUser();
  const { progressOpen, progressClose } = useProgress();

  const handleClickProfile = () => {
    navigate('/profile')
  };

  /**
   * 로그아웃을 처리한다
   */
  const handleClickLogout = async () => {
    try {
      progressOpen('로그아웃', '로그아웃 중입니다.');
      await authApi.logout();
      localStorage.removeItem(TOKEN_KEY);
      clearUser();

      navigate('/login');
    } catch (error: any) {

    } finally {
      progressClose();
    }
  };

  return (
    <React.Fragment>
      <Menu
        label={user?.name || ''}
        sx={{
          marginLeft: 'auto'
        }}
        autoClose
        >
          <List dense>
            <ListItemButton
              onClick={handleClickProfile}>
              <ListItemText primary={'프로필 수정'} />
            </ListItemButton>
            <ListItemButton
              onClick={handleClickLogout}>
              <ListItemText primary={'로그아웃'} />
            </ListItemButton>
          </List>
      </Menu>
    </React.Fragment>
  )
};