import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import type { DialogProps } from '@mui/material';
import { useAlert, useProgress } from '@hook';
import { DOC_NEW_FOLDER_ID } from '@const';
import { driveApi } from '@api';

/**
 * onProcess => 처리 시작, 처리 중
 * onCreated => 처리 완료
 * onFailed => 처리 실패
 */
type NewFolderModalProps = DialogProps & {
  driveType: "doc" | "my" | "trash",
  target?: string,

  onCreated: (id: string, result?: any) => void;
  onCancel: (id: string) => void;
  onFailed?: (id: string) => void;
  onProcess?: (id: string) => void;
};

export const NewFolderModal: React.FC<NewFolderModalProps> = (props) => {
  const { target, onCreated, onCancel, onProcess, ...other } = props;
  const { progressOpen, progressClose } = useProgress();
  const { alertOpen } = useAlert();

  const scheme = yup.object().shape({
    foldername: yup.string().required('폴더 이름을 입력해주세요.')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      foldername: ''
    },
    resolver: yupResolver(scheme)
  });

  /**
   * 취소
   */
  const handleCancel = () => {
    setValue('foldername', '');
    onCancel(DOC_NEW_FOLDER_ID);
  };

  /**
   * 데이터 처리
   */
  const handleSubmitProc = async (data: any) => {
    try {
      progressOpen('폴더 생성', '폴더 생성 중입니다.');

      if (target === undefined) {
        throw new Error('선택 폴더가 없습니다.');
      }

      console.log('foldername', data.foldername);

      await driveApi.mkdir(target, data.foldername, props.driveType);
      onCreated(DOC_NEW_FOLDER_ID);
      setValue('foldername', '');
    } catch (error: any) {
      let content: string = error.message;
      if (error.statusCode) {
        if (error.statusCode === 400) {
          content = '동일한 이름의 폴더가 존재합니다.';
        }
      }

      if (error.status) {
        content = '폴더 이름이 잘못되었습니다.';
      }

      alertOpen({
        title: '오류',
        content
      });
    } finally {
      progressClose();
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      {...other}
    >
      <Box
        component={'form'}
        onSubmit={handleSubmit(handleSubmitProc)}
      >
        <DialogTitle>폴더 생성</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            color="secondary"
            size="small"
            fullWidth
            placeholder="폴더 이름을 입력해주세요."
            hiddenLabel
            {...register('foldername')}
            error={errors.foldername ? true : false}
            helperText={errors.foldername?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={handleCancel}
          >
            취소
          </Button>
          <Button
            color="secondary"
            type="submit"
          >
            생성
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
};