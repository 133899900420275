import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormGroup, FormControlLabel, Button } from '@mui/material';
import {
  ExpandMore as MoreIcon
} from '@mui/icons-material';
import { blue, grey } from '@mui/material/colors';

const ITEM_MIN_HEIGHT = 48;
const ITEM_MORE = 5;

export type FilterItem = {
  label: string,
  value: string,
}

type FilterFormProps = {
  label: string,
  items: FilterItem[],
  onSelected: (selected: readonly string[]) => void
};

/**
 * Filter Form
 * @param props
 * @returns
 */
export const FilterForm: React.FC<FilterFormProps> = (props) => {
  const {
    label,
    items,
    onSelected
  } = props
  const [expanded, setExpanded] = useState<boolean>(true);
  const [more, setMore] = useState<boolean>(false);
  const [selected, setSelected] = useState<readonly string[]>([]);

  useEffect(() => {
    const newSelected: readonly string[] = items.map(({ value }) => value);
    setSelected(newSelected);
  }, [items]);

  const handleAllClick = () => {
    if (selected.length === items.length) {
      setSelected([]);
      onSelected([]);
      return;
    }

    const newSelected: readonly string[] = items.map(({ value }) => value);
    setSelected(newSelected);
    onSelected(newSelected);
  };

  const handleMoreClick = () => {
    setMore(true);
  };

  const isSelected = (value: string) => selected.indexOf(value) !== -1;

  /**
   * 데이터 선택
   * @param event
   */
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const selectedIndex: number = selected.findIndex((v) => v === value);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    onSelected(newSelected);
  };

  return (
    <Accordion
      sx={{ p: 0 }}
      elevation={0}
      expanded={expanded}>
      <AccordionSummary
        expandIcon={<MoreIcon />}
        onClick={() => {
          setExpanded(!expanded);
        }}
        >
        {label}
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Button
            onClick={handleAllClick}
            sx={{
              px: 0,
              minWidth: 0,
              color: `${selected.length === items.length ? grey[400] : blue[700]}`
            }}
            >
            {selected.length === items.length ? '모두 해제' : '모두 선택'}
          </Button>
        </Box>
        <Box sx={{
          maxHeight: more ? (ITEM_MIN_HEIGHT * (items.length || 0)) : (ITEM_MIN_HEIGHT * ITEM_MORE),
          overflow: more ? 'initial' : 'hidden'
        }}>
        {items.length > 0 && items.map((item) => (
          <FormGroup
            key={item.value}
            sx={{
              minHeight: ITEM_MIN_HEIGHT,
              justifyContent: 'center',
              [`&:last-child`]: {
                mb: 1
              }
            }}
            >
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  sx={{ py: 0 }}
                  value={item.value}
                  checked={isSelected(item.value)}
                  onChange={handleCheckChange}
                  />
              }
              label={item.label}
              />
          </FormGroup>
        ))}
        </Box>
        {(items.length > ITEM_MORE && !more) && (
          <Box sx={{ mb: 1 }}>
            <Button
              component="a"
              color="secondary"
              onClick={handleMoreClick}
              sx={{ px: 0, minWidth: 0 }}
              >
                더보기
            </Button>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  )
}